import React, { useEffect, useRef, useState } from "react";
import styles from "./CsrPage.module.css";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
const server = process.env.REACT_APP_SERVER_URL;
const MAX_FILE_SIZE = 850 * 1024;

const CsrPage = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    projectId: "",
    YtVideoUrl: "",
    glimpse: "",
    glimpseimg: "",
    post1text: "",
    post2text: "",
    post3text: "",
    post1img: "", 
    post2img: "",
    post3img: "",
    imageP1: null,
    imageP2: null,
    imageP3: null,
    imageG: null
  });

  const [previewImageP1, setPreviewImageP1] = useState(null);
  const [previewImageP2, setPreviewImageP2] = useState(null);
  const [previewImageP3, setPreviewImageP3] = useState(null);
  const [previewImageG, setPreviewImageG] = useState(null);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  const fetchProject = async () => {
    try {
      const response = await axios.get(`/csr`);
      console.log("fetchbyId:::", response.data[0]);
      setInputs(response.data);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;
    console.log("show files::", files);
    // Update specific fields within offerProject

    setInputs((values) => {
      if (name === "imageP1") {
        const uniqueFileNames = Array.from(files).map(file => ({
          name: uuidv4() + '_' + file.name.replace(/\s+/g, ''),
          file
        }));

        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImageP1(reader.result);
        };
        const imageFile = uniqueFileNames[0];
        // Check if file exists before reading
        if (imageFile?.file) {
          reader.readAsDataURL(imageFile.file);
        } else {
          setPreviewImageP1(null);
        }
        return {
          ...values,
          post1img: uniqueFileNames[0].name,
          imageP1: event.target.files[0]
        };
      } else if (name === "imageP2") {
        const uniqueFileNames = Array.from(files).map(file => ({
          name: uuidv4() + '_' + file.name.replace(/\s+/g, ''),
          file
        }));

        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImageP2(reader.result);
        };
        const imageFile = uniqueFileNames[0];
        // Check if file exists before reading
        if (imageFile?.file) {
          reader.readAsDataURL(imageFile.file);
        } else {
          setPreviewImageP2(null);
        }
        return {
          ...values,
          post2img: uniqueFileNames[0].name,
          imageP2: event.target.files[0]
        };
      } else if (name === "imageP3") {
        const uniqueFileNames = Array.from(files).map(file => ({
          name: uuidv4() + '_' + file.name.replace(/\s+/g, ''),
          file
        }));

        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImageP3(reader.result);
        };
        const imageFile = uniqueFileNames[0];
        // Check if file exists before reading
        if (imageFile?.file) {
          reader.readAsDataURL(imageFile.file);
        } else {
          setPreviewImageP3(null);
        }
        return {
          ...values,
          post3img: uniqueFileNames[0].name,
          imageP3: event.target.files[0]
        };
      } else if (name === "imageG") {
        const uniqueFileNames = Array.from(files).map(file => ({
          name: uuidv4() + '_' + file.name.replace(/\s+/g, ''),
          file
        }));

        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImageG(reader.result);
        };
        const imageFile = uniqueFileNames[0];
        // Check if file exists before reading
        if (imageFile?.file) {
          reader.readAsDataURL(imageFile.file);
        } else {
          setPreviewImageG(null);
        }
        return {
          ...values,
          glimpseimg: uniqueFileNames[0].name,
          imageG: event.target.files[0]
        };
      } else {
        return {
            ...values,
            [name]: value,
          };
        }
    })
  }


  console.log("check Inputs::", inputs);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log("check INputs after submit::", inputs);
    try {

      const response = await axios.put(`/csr`, inputs);

      // console.log("put Response:::", response.status);
      // console.log("input check for img::", inputs?.imageP1);
      // console.log("input check for img2::", inputs?.imageP2);
      // console.log("previewImageP1", previewImageP1);
      // console.log("previewImageP2", previewImageP2);

      if (inputs?.imageP1 ) {
        console.log("yes img 1");
        const formData = new FormData();

       formData.append("image", inputs?.imageP1, inputs?.post1img);

          const res = await axios.post("/image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log("Response:", res.data);
        }

      if (inputs?.imageP2) {
        console.log("yes img 2");
        const formData = new FormData();

        formData.append("image", inputs.imageP2, inputs.post2img);
       
          const res = await axios.post("/image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log("Response:", res.data);
        }
      if ( inputs?.imageP3 ) {
        const formData = new FormData();

       formData.append("image", inputs.imageP3, inputs.post3img);
       
          const res = await axios.post("/image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log("Response:", res.data);
        }
      if (inputs?.imageG) {
        const formData = new FormData();
console.log("glipmseImgt called");
        formData.append("image", inputs.imageG, inputs.glimpseimg);

          const res = await axios.post("/image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log("Response:", res.data);
        }

      if (response.status === 200 || response.status == 201) {
        toast.success("Location Uploaded Successfully !");

      } else if (response.status == 400) {
        toast.error("Location Name Required");
      }
    } catch (error) {
      setErr("failed to upload !");
      toast.error("Failed to update Data!");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);

    }
  };

  console.log("Home assential input<>>>>", inputs);

  return (
    <div className={styles.container}>
      <h2>CSR Page</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.formSection}>
            <label>
              Title
              <input
                type="text"
                name="title"
                value={inputs?.title}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
              Description
              <textarea
                type="text"
                name="description"
                value={inputs?.description}
                onChange={handleChange}
                className={styles.textArea}
              />
            </label>
          </div>
          <div style={{ marginTop: "2rem", marginBottom: "2rem", border: "1px solid gray", padding: "0.3rem" }} className={styles.formSection}>
            Post 1
            <label>
              Description
              <textarea
                type="text"
                name="post1text"
                value={inputs?.post1text}
                onChange={handleChange}
                className={styles.textArea}
              />
            </label>
            {/* image Section */}
            <div className={styles.formSection}>
              <div className={styles.singleImgSctn}>
                <label>
                  Post 1 Image (Single Image)
                  <input
                    // style={{backgroundColor: "gray"}}
                    type="file"
                    name="imageP1"
                    checked={inputs?.imageP1}
                    onChange={handleChange}
                    className={styles.fileInput}
                  />
                </label>
                {previewImageP1 ? (
                  <img
                    src={previewImageP1}
                    alt="Preview"
                    className={styles.previewImage}
                  />
                ) : <img
                  src={`${server}/image/${inputs?.post1img}`}
                  alt="Preview"
                  className={styles.previewImage}
                />}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "2rem", marginBottom: "2rem", border: "1px solid gray", padding: "0.3rem" }} className={styles.formSection}>
            Post 2
            <label>
              Description
              <textarea
                type="text"
                name="post1text"
                value={inputs?.post2text}
                onChange={handleChange}
                className={styles.textArea}
              />
            </label>
            {/* image Section */}
            <div className={styles.formSection}>
              <div className={styles.singleImgSctn}>
                <label>
                  Post 2 Image (Single Image)
                  <input
                    // style={{backgroundColor: "gray"}}
                    type="file"
                    name="imageP2"
                    checked={inputs?.imageP2}
                    onChange={handleChange}
                    className={styles.fileInput}
                  />
                </label>
                {previewImageP2 ? (
                  <img
                    src={previewImageP2}
                    alt="Preview"
                    className={styles.previewImage}
                  />
                ) : <img
                  src={`${server}/image/${inputs?.post2img}`}
                  alt="Preview"
                  className={styles.previewImage}
                />}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "2rem", marginBottom: "2rem", border: "1px solid gray", padding: "0.3rem" }} className={styles.formSection}>
            Post 3
            <label>
              Description
              <textarea
                type="text"
                name="post3text"
                value={inputs?.post3text}
                onChange={handleChange}
                className={styles.textArea}
              />
            </label>
            {/* image Section */}
            <div className={styles.formSection}>
              <div className={styles.singleImgSctn}>
                <label>
                  Post 3 Image (Single Image)
                  <input
                    // style={{backgroundColor: "gray"}}
                    type="file"
                    name="imageP3"
                    checked={inputs?.imageP3}
                    onChange={handleChange}
                    className={styles.fileInput}
                  />
                </label>
                {previewImageP3 ? (
                  <img
                    src={previewImageP3}
                    alt="Preview"
                    className={styles.previewImage}
                  />
                ) : <img
                  src={`${server}/image/${inputs?.post3img}`}
                  alt="Preview"
                  className={styles.previewImage}
                />}
              </div>
            </div>
          </div>
          <div className={styles.formSection}>
            <label>
              CSR Mission
              <textarea
                type="text"
                name="csrMission"
                value={inputs?.csrMission}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
            CSR Vission
              <textarea
                type="text"
                name="csrVission"
                value={inputs?.csrVission}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
        </div>

        <div style={{ marginTop: "2rem", marginBottom: "2rem", border: "1px solid gray", padding: "0.3rem" }} className={styles.formSection}>
        GLIMPSE CSR ACTIVITIES
            <label>
              Description
              <textarea
                type="text"
                name="glimpse"
                value={inputs?.glimpse}
                onChange={handleChange}
                className={styles.textArea}
              />
            </label>
            {/* image Section */}
            <div className={styles.formSection}>
              <div className={styles.singleImgSctn}>
                <label>
                  Image
                  <input
                    // style={{backgroundColor: "gray"}}
                    type="file"
                    name="imageG"
                    checked={inputs?.imageG}
                    onChange={handleChange}
                    className={styles.fileInput}
                  />
                </label>
                {previewImageG ? (
                  <img
                    src={previewImageG}
                    alt="Preview"
                    className={styles.previewImage}
                  />
                ) : <img
                  src={`${server}/image/${inputs?.glimpseimg}`}
                  alt="Preview"
                  className={styles.previewImage}
                />}
              </div>
            </div>
          </div>

        <button
          style={{ marginTop: "2vw", backgroundColor: loading && "#584db8" }}
          type="submit"
          className={styles.submitButton}
        >
          {loading ? "Uploading...., Please Wait" : "Update CSR Page"}
        </button>
      </form>
    </div>
  );
};

export default CsrPage;
