// AddNewProject.js
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import axios from "../../../api/axios";
import styles from "./EditMaterials.module.css";
import { useParams } from "react-router-dom";
const server = process.env.REACT_APP_SERVER_URL

function EditMaterials() {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)

  const [singleGreenData, setSingleGreenData] = useState({
    name: "",
    details: ""
  });

  useEffect(() => {
    const fetchGreenData = async () => {
      try {
        const response = await axios.get(`/materials/${id}`);
        setSingleGreenData(response.data);
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };
    fetchGreenData();
  }, [id]);
;

console.log("singleGreenData:::", singleGreenData)
  const handleChange = (event) => {
    const { name, value} = event.target;

    // Handle different types of input elements
    setSingleGreenData((values) => {
        return {
          ...values,
          [name]: value,
        };
      }
    );
  }

  console.log("edit Materials:::", singleGreenData);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.put(`/materials/${id}`, singleGreenData);

      if (response.status === 200 || response.status === 201) {
        toast.success("Client Updated Successfully !")
      } else if (response.status === 400) {
        toast.error("Client is not Updated!")
      }

      console.log("Response:", response.data);
    } catch (error) {
      console.log("error:::", error);
      toast.error("Unexprected Error Found !")
      console.error("Error submitting form:", error);
    }
    console.log(singleGreenData);
  };

  return (
    <div className={styles.container}>
      <h2>Update Data</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          {/* Project Details */}
          {/* name and Desctiption */}
          <div className={styles.formSection}>
            <label>
            Material Name:
              <input
                type="text"
                name="name"
                value={singleGreenData?.name}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
            Details:
              <textarea
                type="text"
                name="details"
                value={singleGreenData?.details}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
  
        </div>
        <button type="submit" className={styles.submitButton}>
          Update
        </button>
      </form>
    </div>
  );
}

export default EditMaterials;
