import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import axios from "../../../api/axios";
import { useAppContext } from "../../../context/AuthProvider";

import style from "./LandonwerReviews.module.css";
import "react-toastify/dist/ReactToastify.css";
import AlertModal from "../../Modals/AlertModal";
import AskModal from "../../Modals/AskModal";
import DeleteNews from "../../clientReviewCURD/deleteReview/DeleteReview";
import DeleteReview from "../../landOwnerReviewCURD/deleteReview/DeleteReview";
const server = process.env.REACT_APP_SERVER_URL

const LandOwnerReviews = () => {
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [reviewId, setReviewId] = useState("")
  const [imageUrl, setImageUrl] = useState("")

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`/landownerReview`);
      setReviewData(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchLocations();
  }, []);

  const onUpdateLocation = async () => {
    try {
      await fetchLocations();
    } catch (error) {
      setError(error);
    }
  };

  console.log("lcoation=>>", reviewData)
  if (loading) {
    return <h3>Loading.....</h3>
  }
  console.log("location data::", reviewData);
  console.log("env::server::", server)
  return (
    <>
      <div className={style.pageHeader}>
        <h2 className={style.title}>Manage all Landowner Reviews</h2> 
        <div className={style['order-button-holder']}>
          <div className={style['filterItem']}>
          </div>
          <div className={style['filterItem']}>
            <NavLink to="/addLandownerReview">
              <div className="addLocationBtn">+ Add Reviews</div>
            </NavLink>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className={style["order-table"]}>
          <thead className={style["order-heading"]}>
            <tr>
              <th></th>
              <th>Landowner Name</th>
              <th>Designation</th>
            </tr>
          </thead>
          <tbody>
            {reviewData.map((n) => (
              <tr key={"00"} className="order-row">
                <td></td>
                <td>{n.name}</td>
              <td>{n.designation}</td>
                <td>
                <NavLink to={`/editLWReview/${n.landownerid}`}>
                    <button className="btn"> Edit</button>
                  </NavLink>
                </td>
                <td>
                  <button
                    className="btn"
                    onClick={() => { setOpenModal(true); setReviewId(n.landownerid); setImageUrl(n.imageUrl) }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}

          </tbody>
          {openModal && (
            <AskModal open={openModal} onClose={() => setOpenModal(false)}>
              <DeleteReview reviewId={reviewId} imageUrl={imageUrl} setOpenModal={setOpenModal}
                onUpdateLocation={onUpdateLocation} />
            </AskModal>
          )}
        </table>

      </div>
    </>
  );
};

export default LandOwnerReviews;
