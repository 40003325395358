import React, { useEffect, useState } from 'react';
import styles from './Sustainability.module.css';
import { toast } from "react-toastify";
import axios from "./../../../api/axios";
import { v4 as uuidv4 } from "uuid";
import { NavLink, useNavigate } from "react-router-dom";
import AskModal from '../../Modals/AskModal';
import DeleteGreen from '../../greenSectionCURD/deleteGreen/DeleteGreen';
import DeleteMaterials from '../../materialsSectionCURD/deleteMaterials/DeleteMaterials';
const server = process.env.REACT_APP_SERVER_URL

const Sustainability = () => {
  const [inputs, setInputs] = useState({
    qualitytext: "",
    globaltext: "",
    certificatetext: "",
    qualityimg: "",
    qualityMainImage: null,
    imageUrl: "",
    image: null,
  })

  const navigate = useNavigate();

  const [qualityPreviewImage, setQualityPreviewImage] = useState(null);
  const [oldImgQuality, setOldImgQuality] = useState("")

  const [globalPreviewImage, setGlobalPreviewImage] = useState(null);
  const [oldImgGlobal, setOldImgGlobal] = useState("")

  const [certifiPreviewImage, setCertifiPreviewImage] = useState(null);
  const [oldImgCertifi, setOldImgCertifi] = useState("")

  const [openModal, setOpenModal] = useState(false);
  const [openModalGreen, setOpenModalGreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [greenConceptData, setGreenConceptData] = useState([]);
  const [materialsData, setMaterialsData] = useState([])
  const [greenId, setGreenId] = useState("")
  const [materialsId, setMaterialsId] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [err, setErr] = useState("")
  const [error, setError] = useState(null);

  const fetchGreen = async () => {
    try {
      const response = await axios.get(`/green`);
      console.log("fetchbyId:::", response.data[0])
      setGreenConceptData(response.data)
      // setInputs(response.data[0]);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await axios.get(`/materials `);
      console.log("fetchbyId:::", response.data[0])
      setMaterialsData(response.data)
      // setInputs(response.data[0]);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  const fetchOtherInfo = async () => {
    try {
      const response = await axios.get(`/otherinfo `);
      console.log("Other Info:::", response.data)
      // setMaterialsData(response.data)
      setInputs(response.data);
      setOldImgQuality(response.data.qualityimg)
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  useEffect(() => {
    fetchGreen();
    fetchMaterials();
    fetchOtherInfo()
  }, []);

  const onUpdateGreen = async () => {
    try {
      await fetchGreen();
    } catch (error) {
      setError(error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setInputs((values) => {
      if (type === "file") {
        const uniqueFileNames = Array.from(files).map((file) => ({
          name: uuidv4() + "_" + file.name.replace(/\s+/g, ""),
          file,
        }));

        if (name === "image") {
          // Handle single image upload
          const imageFile = uniqueFileNames[0];
          const reader = new FileReader();
          reader.onload = () => {
            setQualityPreviewImage(reader.result);
          };
          reader.readAsDataURL(imageFile.file);
          return {
            ...values,
            qualityimg: uniqueFileNames[0].name,
            qualityMainImage: event.target.files[0]
          };
        }

      } else {
        // Handle other input types
        return {
          ...values,
          [name]: value
        };
      }
    });
  };

  console.log('inputs>>>', inputs);
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    try {
      console.log("posting", inputs);
      const response = await axios.put(
        `/otherinfo`,
        inputs
      );

      if (inputs.qualityMainImage) {
        //uplaod the image
        const formData = new FormData();
        formData.append("image", inputs.qualityMainImage, inputs.qualityimg);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }


      if (response.status === 200 || response.status === 201) {
        const res = await axios.delete(`/image/${oldImgQuality}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Uploaded Successfully !")
      } else if (response.status === 400) {
        toast.error("Name Required")
      }
    } catch (error) {
      setErr("failed to upload !")
      toast.error("Failed to update Data!")
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false)
    }

    console.log(inputs);
  };

  console.log("Home assential input<>>>>", inputs);

  return (
    <div className={styles.container}>
      <h2 style={{ marginBottom: "1vw" }} >Update Sustainability Page Data</h2>

      {/* Green building Section  */}
      <div className={styles.section} >
        <h3>Green Building Practices</h3>
        <NavLink to="/addGreen">
          <div className={styles.addGreenBtn}>+ Add New</div>
        </NavLink>

        <div styles={{ overflowX: "auto" }}>
          {greenConceptData.map((n) => (
            <tr key={"00"} className={styles.orderRow}>
              <td>{n.title}</td>
              <td></td>
              <td>
                <NavLink to={`/editGreen/${n.id}`}>
                  <button className={styles.Btn}> Edit</button>
                </NavLink>
              </td>
              <td>
                <button
                  className={styles.Btn}
                  onClick={() => { setOpenModal(true); setGreenId(n.id) }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}

          {openModalGreen && (
            <AskModal open={openModalGreen} onClose={() => setOpenModalGreen(false)}>
              <DeleteGreen greenId={greenId} imageUrl={imageUrl} setOpenModalGreen={setOpenModalGreen}
                onUpdateGreen={onUpdateGreen} />
            </AskModal>
          )}
        </div>
      </div>

      {/* Material specification Section  */}
      <div className={styles.section} >
        <h3>Materials Specification</h3>
        <NavLink to="/addMaterials">
          <div className={styles.addGreenBtn}>+ Add New</div>
        </NavLink>

        <div>
          <div className={styles.tableBox} >
            {materialsData.map((n) => (
              <tr key={"00"} className={styles.tableRow}>
                <td>{n.name}</td>
                <td></td>

                <td>
                  <NavLink to={`/editMaterials/${n.id}`}>
                    <button className={styles.Btn}> Edit</button>
                  </NavLink>
                </td>
                <td>
                  <button
                    className={styles.Btn}
                    onClick={() => { setOpenModal(true); setMaterialsId(n.id) }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}

          </div>
          {openModal && (
            <AskModal open={openModal} onClose={() => setOpenModal(false)}>
              <DeleteMaterials materialsId={materialsId} imageUrl={imageUrl} setOpenModal={setOpenModal}
                onUpdateGreen={onUpdateGreen} />
            </AskModal>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit} className={styles.formContainer}>

        <div className={styles.fieldContainer}>
          <div className={styles.formSection}>
            <h3>Quality Of Secure</h3>
            <label>
              Description
              <textarea
                type="text"
                name="qualitytext"
                value={inputs.qualitytext}
                onChange={handleChange}
                className={styles.textarea}
              />
            </label>
            <>
              <label>
                Quality Section Image (Single Image)
                <input
                  // style={{backgroundColor: "gray"}}
                  type="file"
                  name="image"
                  checked={inputs.image}
                  onChange={handleChange}
                  className={styles.fileInput}
                />
              </label>
              {qualityPreviewImage ? (
                <img
                  src={qualityPreviewImage}
                  alt="Preview"
                  className={styles.previewImage}
                />
              ) : <img
                src={`${server}/image/${inputs.qualityimg}`}
                alt="Preview"
                className={styles.previewImage}
              />}
           </>
          </div>
          <div className={styles.formSection}>
            <h3>Global Architect And Structural Design</h3>
            <label>
              Description
              <textarea
                type="text"
                name="globaltext"
                value={inputs.globaltext}
                onChange={handleChange}
                className={styles.textarea}
              />
            </label>
            {/* <>
              <label>
                Quality Section Image (Single Image)
                <input
                  // style={{backgroundColor: "gray"}}
                  type="file"
                  name="image"
                  checked={inputs.image}
                  onChange={handleChange}
                  className={styles.fileInput}
                />
              </label>
              {globalPreviewImage ? (
                <img
                  src={globalPreviewImage}
                  alt="Preview"
                  className={styles.previewImage}
                />
              ) : <img
                src={`${server}/image/${inputs.blobalimg}`}
                alt="Preview"
                className={styles.previewImage}
              />}
           </> */}
          </div>

          <div className={styles.formSection}>
            <h3>Certification</h3>
            <label>
              Description
              <textarea
                type="text"
                name="certificatetext"
                value={inputs.certificatetext}
                onChange={handleChange}
                className={styles.textarea}
              />
            </label>
          </div>
        </div>

        <button style={{ marginTop: "2vw", backgroundColor: loading && "#584db8" }} type="submit" className={styles.submitButton}>
          {loading ? "Loading...." : "Update Info"}
        </button>
      </form>

    </div>
  );
};

export default Sustainability;
