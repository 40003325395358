import { useState } from "react";
import axios from "../../../api/axios"


const DeleteProject = ({ projectId, setOpenModal, onUpdateProject  }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleYesClick = async () => {
        setLoading(true);
        try {
            const res = await axios.delete(`/projects/${projectId}`);
            console.log("delete response:", res);
            // Close the modal after successful deletion
            setOpenModal(false);
            onUpdateProject() 
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseClick = () => {
        setOpenModal(false); 
    };

    return (
        <>
          <h4> Are you want to delete the Location?</h4> 
            <div className="ask-alert-bottons">
                <button className="ask-close-btn" onClick={handleYesClick}>Yes</button>
                <button className="ask-close-btn" onClick={handleCloseClick}>Cancel</button>
            </div>
        </>
    )
}

export default DeleteProject;
