import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const useAppContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // Check if there's a saved authentication state in local storage
  const savedAuth = localStorage.getItem("auth");
  const saveToken = localStorage.getItem("token");

  const [auth, setAuth] = useState(savedAuth ? JSON.parse(savedAuth) : {});
  const [token, setToken] = useState(saveToken || "");
  const [toggleLeftSidebar, setToggleLeftSidebar] = useState(false);
  const [toggleTopbar, setToggletopbar] = useState(false);

  // Effect to save the authentication state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
    localStorage.setItem("token", token);
  }, [auth, token]);

  const value = {
    auth,
    setAuth,
    toggleLeftSidebar,
    setToggleLeftSidebar,
    toggleTopbar,
    setToggletopbar,
    setToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
