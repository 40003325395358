import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import axios from "../../../api/axios";
import { useAppContext } from "../../../context/AuthProvider";

import style from "./FormData.module.css";
import "react-toastify/dist/ReactToastify.css";
import AlertModal from "../../Modals/AlertModal";
import AskModal from "../../Modals/AskModal";
import DeleteNews from "../../clientReviewCURD/deleteReview/DeleteReview";
import DeleteReview from "../../clientReviewCURD/deleteReview/DeleteReview";
import View from "./view/View";
import DeleteQuery from "./deleteQuery/DeleteQuery";
const server = process.env.REACT_APP_SERVER_URL

const FormData = () => {
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [reviewId, setReviewId] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [view, setView] = useState({})

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`/formData`);
      console.log("res:::", response);
      setReviewData(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchLocations();
  }, []);

  const onUpdateLocation = async () => {
    try {
      await fetchLocations();
    } catch (error) {
      setError(error);
    }
  };

  console.log("form data=>>", reviewData)


  const handleCloseClick = () => {
    setOpenModalView(false);
  };

  if (loading) {
    return <h3>Loading.....</h3>
  }

  return (
    <>
      <div className={style.pageHeader}>
        <h2 className={style.title}>Form Data</h2>
        <div className={style['order-button-holder']}>
          <div className={style['filterItem']}>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className={style["order-table"]}>
          <thead className={style["order-heading"]}>
            <tr>
              <th></th>
              <th>Client Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Query For</th>
            </tr>
          </thead>
          <tbody>
            {reviewData.map((n) => (
              <tr key={"00"} className={style["order-row"]}>
                <td></td>
                <td>{n.name}</td>
                <td>{n.phone}</td>
                <td>{n.email}</td>
                <td>{n.queryFor}</td>
                <td>
                  <button
                    className="btn"
                    onClick={() => { setOpenModalView(true); setView(n) }}
                  >
                    View
                  </button>
                  {/* {openModalView && (
                    <AskModal open={openModalView} onClose={() => setOpenModalView(false)}>
                      <div className={style.popup} >
                      <td>Name:00 {n.name}</td>
                      <td>Phone: {n.phone}</td>
                      <td>Email: {n.email}</td>
                      <td>Query For: {n.queryFor}</td>
                      <td>Message: {n.message ? n.message : "No Message" }</td>
                        <button style={{marginTop: "1rem"}} className="ask-close-btn" onClick={handleCloseClick}>Close</button>
                      </div>
                    </AskModal>
                  )} */}

                </td>
                <td>
                  <button
                    className="btn"
                    onClick={() => { setOpenModal(true); setReviewId(n.id); setImageUrl(n.imageUrl) }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          {openModal && (
            <AskModal open={openModal} onClose={() => setOpenModal(false)}>
              <DeleteQuery  reviewId={reviewId} imageUrl={imageUrl} setOpenModal={setOpenModal}
              onUpdateLocation={onUpdateLocation} 
              />
            </AskModal>
          )}
          {openModalView && (
            <AskModal open={openModalView} onClose={() => setOpenModalView(false)}>
              <View  view={view} imageUrl={imageUrl} setOpenModalView={setOpenModalView}
              onUpdateLocation={onUpdateLocation} 
              />
            </AskModal>
          )}

        </table>

      </div>
    </>
  );
};

export default FormData;
