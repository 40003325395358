import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import axios from "../../../api/axios";
import { useAppContext } from "../../../context/AuthProvider";
import Modal from "../../Modals/Modal";
import styles from "./AllProject.module.css";
import AskModal from "../../Modals/AskModal";
import DeleteProject from "../../ProjectCURD/deleteProject/DeleteProject";
const server = process.env.REACT_APP_SERVER_URL
// Import CSS Modules

const AllProject = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [projectId, setProjectId] = useState("")

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`/projects`);
      setProjects(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);


  const onUpdateProject = async () => {
    try {
        await fetchProjects();
    } catch (error) {
        setError(error);
    }
};


  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDeleteClick = async (pId) => {
    try {
      setLoading(true)
      const response = await axios.delete(
        `/projects/${pId}`
      );
      console.log(response.status)
      fetchProjects();
      setLoading(false);
    }catch (err) {
      setError(error);
      setLoading(false);
    }
   
    setShowConfirmation(true);
  };



  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  console.log("projects=<", projects[0]);

  return (
    <>
      <div className={styles.pageHeader}>
        <h2 className={styles.title}>All Projects</h2>
        <div className={styles.orderButtonHolder}>
          <p>Total Projects: {projects.length}</p>
          <NavLink to="/addNewProject">
            <div className={styles.add}>+ Add Projects</div>
          </NavLink>
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className={styles.orderTable}>
          <thead className={styles.orderHeading}>
            <tr>
              <th>Project Id</th>
              <th>Name</th>
              <th>Location</th>
              <th>Type</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.projectId} className={styles.orderRow}>
                <td>{project.projectId}</td>
                <td>{project.name}</td>
                <td>{project.location}</td>
                <td>{project.developmentType}</td>
                <td><img className="imageView" src={`${server}/image/${project.imageName ? project.imageName : "2a4211a7-73dc-43d6-b85f-1994cf22061a_nice-nature-image.jpg"}`} /></td>
                <td>
                <NavLink to={`/editProject/${project.projectId}`}>
                    <button className={styles.btn}>Edit</button>
                  </NavLink>
                </td>
                <td>
                  <NavLink >
                    <button className={styles.btn} 
                    onClick={() => {setOpenModal(true); setProjectId(project.projectId)}}
                    >Delete</button>
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
          {openModal && (
            <AskModal open={openModal} onClose={() => setOpenModal(false)}>
              <DeleteProject projectId={projectId} setOpenModal={setOpenModal}
              onUpdateProject={onUpdateProject}/>
            </AskModal>
          )}
        </table>
      </div>
      <Modal>{/* Modal content */}</Modal>
      <ToastContainer />
    </>
  );
};

export default AllProject;


