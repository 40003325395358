import React, { useState, useEffect } from 'react';
import styles from './ContactInfo.module.css';
import axios from "./../../../api/axios"
import { toast } from 'react-toastify';

const ContactPage = () => {
  const initialFormData = {
    chittagong_office: {
      address: '',
      email: '',
      phone: ''
    },
    corporate_office: {
      address: '',
      email: '',
      phone: ''
    },
    contact: {
      whatsapp: '',
      email: '',
      DHK: '',
      CTG: ''
    },
    received_email: '',
    social_links: {
      fb: '',
      in: '',
      yt: '',
      x: ''
    },
    location_map: {
      dhk: {
        lat: '',
        lan: ''
      },
      ctg: {
        lat: '',
        lan: ''
      }
    }
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    // Fetch data and pre-fill the form
    const fetchData = async () => {
      try {
        const response = await axios.get(`/contactInfo`);
        const jsonData = await response.data;
        setFormData(jsonData);
      } catch (error) {
        console.error('Error fetching contact information:', error);
      }
    };

    fetchData();
  }, []);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(`/contactInfo`, formData);
      console.log("response:::", response);
      toast.success("Data Updated Succesfully")
    } catch (err) {
      toast.error("Something Went wrong")
      console.log("err", err);
    }

    // Perform form submission logic (e.g., update contact information)
    // You can use the formData state to send the updated data to your backend
  };

  console.log("formData::", formData)

  return (
    <div className={styles.container}>
      <h2>Update Contact Information</h2>
      <form onSubmit={handleSubmit}>
        {/* Chittagong Office */}
        <h3>Chittagong Office</h3>
        <div className={styles.labelContainer}>
          <label htmlFor="chittagongOfficeAddress">Address:</label>
          <div className={styles.inputContainer}>
            <input
              type="text"
              id="chittagongOfficeAddress"
              value={formData.chittagong_office.address}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                chittagong_office: {
                  ...prevState.chittagong_office,
                  address: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="chittagongOfficeEmail">Email:</label>
          <div className={styles.inputContainer}>
            <input
              type="email"
              id="chittagongOfficeEmail"
              value={formData.chittagong_office.email}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                chittagong_office: {
                  ...prevState.chittagong_office,
                  email: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="chittagongOfficePhone">Phone:</label>
          <div className={styles.inputContainer}>
            <input
              type="tel"
              id="chittagongOfficePhone"
              value={formData.chittagong_office.phone}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                chittagong_office: {
                  ...prevState.chittagong_office,
                  phone: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>
        {/* Include similar fields for Corporate Office */}
        <h3>Corporate Office</h3>
        <div className={styles.labelContainer}>
          <label htmlFor="corporateOfficeAddress">Address:</label>
          <div className={styles.inputContainer}>
            <input
              type="text"
              id="corporateOfficeAddress"
              value={formData.corporate_office.address}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                corporate_office: {
                  ...prevState.corporate_office,
                  address: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="corporateOfficeEmail">Email:</label>
          <div className={styles.inputContainer}>
            <input
              type="email"
              id="corporateOfficeEmail"
              value={formData.corporate_office.email}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                corporate_office: {
                  ...prevState.corporate_office,
                  email: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="corporate_officePhone">Phone:</label>
          <div className={styles.inputContainer}>
            <input
              type="tel"
              id="corporate_officePhone"
              value={formData.corporate_office.phone}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                corporate_office: {
                  ...prevState.corporate_office,
                  phone: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>

        {/* Include similar fields for Contact */}
        <h3>Contact</h3>

        {/* Include similar fields for Contact */}
        <div className={styles.labelContainer}>
          <label htmlFor="whatsapp">WhatsApp Number:</label>
          <div className={styles.inputContainer}>
            <input
              type="text"
              id="whatsapp"
              value={formData.contact.whatsapp}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                contact: {
                  ...prevState.contact,
                  whatsapp: e.target.value
                }
              }))}
         
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="contactEmail">Contact Email:</label>
          <div className={styles.inputContainer}>
            <input
              type="email"
              id="contactEmail"
              value={formData.contact.email}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                contact: {
                  ...prevState.contact,
                  email: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="contactDHK">DHK Contact:</label>
          <div className={styles.inputContainer}>
            <input
              type="text"
              id="contactDHK"
              value={formData.contact.DHK}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                contact: {
                  ...prevState.contact,
                  DHK: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="contactCTG">CTG Contact:</label>
          <div className={styles.inputContainer}>
            <input
              type="text"
              id="contactCTG"
              value={formData.contact.CTG}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                contact: {
                  ...prevState.contact,
                  CTG: e.target.value
                }
              }))}
              required
            />
          </div>
        </div>

        {/* Include similar fields for Received Email */}

        {/* Include similar fields for Social Links */}
        <h3>Social Media Links</h3>
        <div className={styles.labelContainer}>
          <label htmlFor="socialLinksFb">Facebook:</label>
          <div className={styles.inputContainer}>
            <input
              type="url"
              id="socialLinksFb"
              value={formData.social_links.fb}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                social_links: {
                  ...prevState.social_links,
                  fb: e.target.value
                }
              }))}
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="socialLinksIn">LinkedIn:</label>
          <div className={styles.inputContainer}>
            <input
              type="url"
              id="socialLinksIn"
              value={formData.social_links.in}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                social_links: {
                  ...prevState.social_links,
                  in: e.target.value
                }
              }))}
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="socialLinksYt">YouTube:</label>
          <div className={styles.inputContainer}>
            <input
              type="url"
              id="socialLinksYt"
              value={formData.social_links.yt}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                social_links: {
                  ...prevState.social_links,
                  yt: e.target.value
                }
              }))}
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <label htmlFor="socialLinksX">Twitter:</label>
          <div className={styles.inputContainer}>
            <input
              type="url"
              id="socialLinksX"
              value={formData.social_links.x}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                social_links: {
                  ...prevState.social_links,
                  x: e.target.value
                }
              }))}
            />
          </div>
        </div>

        <h3>Contact Map info</h3>
        {/* Include similar fields for Location Map */}
        {/* Include similar fields for Location Map */}
        <div className={styles.twoDiv} >
        <div className={styles.labelContainer}>
          <div className={styles.inputContainer}>
            <label htmlFor="locationMapDhkLat">Dhaka Latitude:</label>
            <input
              type="number"
              step="any"
              id="locationMapDhkLat"
              value={formData?.location_map?.dhk?.lat}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                location_map: {
                  ...prevState.location_map,
                  dhk: {
                    ...prevState.location_map.dhk,
                    lat: e.target.value
                  }
                }
              }))}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="locationMapDhkLan">Dhaka Longitude:</label>
            <input
              type="number"
              step="any"
              id="locationMapDhkLan"
              value={formData.location_map.dhk?.lan}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                location_map: {
                  ...prevState.location_map,
                  dhk: {
                    ...prevState.location_map.dhk,
                    lan: e.target.value
                  }
                }
              }))}
              required
            />
          </div>
        </div>
        <div className={styles.labelContainer}>
          <div className={styles.inputContainer}>
            <label htmlFor="locationMapCtgLat">Chittagong Latitude:</label>
            <input
              type="number"
              step="any"
              id="locationMapCtgLat"
              value={formData?.location_map?.ctg?.lat}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                location_map: {
                  ...prevState.location_map,
                  ctg: {
                    ...prevState.location_map.ctg,
                    lat: e.target.value
                  }
                }
              }))}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="locationMapCtgLan">Chittagong Longitude:</label>
            <input
              type="number"
              step="any"
              id="locationMapCtgLan"
              value={formData.location_map.ctg?.lan}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                location_map: {
                  ...prevState.location_map,
                  ctg: {
                    ...prevState.location_map.ctg,
                    lan: e.target.value
                  }
                }
              }))}
              required
            />
          </div>
        </div>
        </div>

          {/* others */}
        <h3>Others info</h3>
        <div className={styles.labelContainer}>
          <label htmlFor="receivedEmail">Form Data Received Email:</label>
          <div className={styles.inputContainer}>
            <input
              type="email"
              id="receivedEmail"
              value={formData.received_email}
              onChange={(e) => setFormData(prevState => ({
                ...prevState,
                received_email: e.target.value
              }))}
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        <button type="submit">Update Contact Information</button>
      </form>
    </div>
  );
};

export default ContactPage;
