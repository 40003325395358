import React from "react";
import ReactDom from "react-dom";

function Modal({ open, children, onClose }) {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div className="overlay" />
      <div className="modal">
        <button className="close-btn" onClick={onClose}></button>
        {children}
      </div>
    </>,
    document.getElementById("modal")
  );
}

export default Modal;
