import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import axios from "../../../api/axios";
import { useAppContext } from "../../../context/AuthProvider";

import style from "./Managements.module.css";
import "react-toastify/dist/ReactToastify.css";
import AlertModal from "../../Modals/AlertModal";
import AskModal from "../../Modals/AskModal";
import DeleteNews from "../../NewsNeventCURD/deleteNewsNevent/DeleteNewsNevent";
import DeleteDirector from "../../directorCRUD/deleteDirector/DeleteDirector";
const server = process.env.REACT_APP_SERVER_URL

const Managements = () => {
  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [directorId, setDirectorId] = useState("")
  const [imageUrl, setImageUrl] = useState("")

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`/director`);
      setClient(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchLocations();
  }, []);

  const onUpdateLocation = async () => {
    try {
      await fetchLocations();
    } catch (error) {
      setError(error);
    }
  };

  console.log("lcoation=>>", client)
  if (loading) {
    return <h3>Loading.....</h3>
  }
  console.log("location data::", client);
  console.log("env::server::", server)
  return (
    <>
      <div className={style.pageHeader}>
        <h2 className={style.title}>Edit Managements Information</h2> 
        <div className={style['order-button-holder']}>
          <div className={style['filterItem']}>
          </div>
          <div className={style['filterItem']}>
            <NavLink to="/addDirector">
              <div className="addLocationBtn">+ Add Member</div>
            </NavLink>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className={style["order-table"]}>
          <thead className={style["order-heading"]}>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {client.map((n) => (
              <tr key={"00"} className="order-row">
                <td></td>
                <td>{n.name}</td>
                <td>
                  <img
                    src={`${server}/image/${n.imgUrl}`}
                    alt={n?.name}
                    className=""
                  />
                </td>
                <td>
                <NavLink to={`/editDirector/${n.id}`}>
                    <button className="btn"> Edit</button>
                  </NavLink>
                </td>
                <td>
                  <button
                    className="btn"
                    onClick={() => { setOpenModal(true); setDirectorId(n.id); setImageUrl(n.imgUrl) }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}

          </tbody>
          {openModal && (
            <AskModal open={openModal} onClose={() => setOpenModal(false)}>
              <DeleteDirector directorId={directorId} imageUrl={imageUrl} setOpenModal={setOpenModal}
                onUpdateLocation={onUpdateLocation} />
            </AskModal>
          )}
        </table>

      </div>
    </>
  );
};

export default Managements;
