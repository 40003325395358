// AddNewProject.js
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import axios from "../../../api/axios";
import styles from "./AddNewLocation.module.css";
import { toast } from "react-toastify";

function AddNewLocation() {
  const [inputs, setInputs] = useState({
    locationName: "",
    imageName: "",
    image: null,
    parentid: ""
  })
  const [location, setLocation] = useState([]);

  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`/location/parent`);
        setLocation(response.data);
        setLoading(false);
      } catch (error) {

        setLoading(false);
      }
    };
    fetchLocations();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setInputs((values) => {
      if (type === "file") {
        const uniqueFileNames = Array.from(files).map((file) => ({
          name: uuidv4() + "_" + file.name.replace(/\s+/g, ""),
          file,
        }));

        if (name === "image") {
          // Handle single image upload
          const imageFile = uniqueFileNames[0];
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(imageFile.file);
          return {
            ...values,
            imageName: uniqueFileNames[0].name,
            image: event.target.files[0]
          };
        }

      } else {
        // Handle other input types
        return {
          ...values,
          [name]: value
        };
      }
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    try {
      console.log("posting", inputs);
      const response = await axios.post(
        `/location`,
        inputs
      );

      if (inputs.image) {
        //uplaod the image
        const formData = new FormData();
        formData.append("image", inputs.image, inputs.imageName);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }

      if (response.status == 200 || response.status == 201) {
        toast.success("Location Uploaded Successfully !")
      } else if (response.status == 400) {
        toast.error("Location Name Required")
      }

    } catch (error) {
      setErr("failed to upload !")
      toast.error("Failed to upload the project!")
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false)
    }

    console.log(inputs);
  };

  console.log("inpt data", inputs);

  return (
    <div className={styles.container}>
      <h2>Add a new Location</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.formSection}>
            <label>
              Location Name:
              <input
                type="text"
                name="locationName"
                value={inputs.locationName}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label> Select Parent location <select
              name="parentid"
              value={inputs.locationId}
              onChange={handleChange}
              className={styles.dropdown}
            >
              <option value="">Select</option>
              {location.map((l) => (
                <>      <option value={l.locationId}>{l.locationName}</option></>
              ))}

            </select>
            </label>
          </div>
        </div>

        {/* image Section */}
        <div className={styles.formSection}>
          <div className={styles.singleImgSctn}>
            <label>
              Location Image (Single Image)
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                name="image"
                checked={inputs.image}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                className={styles.previewImage}
              />
            )}
          </div>
        </div>
        <button style={{ marginTop: "2vw", backgroundColor: loading && "#584db8" }} type="submit" className={styles.submitButton}>
          {loading ? "Loading...." : "Add Location"}
        </button>
      </form>
    </div>
  );
}

export default AddNewLocation;
