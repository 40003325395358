import React, { useEffect, useState } from 'react';
import styles from './OthersInfo.module.css';
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
const server = process.env.REACT_APP_SERVER_URL

const OthersInfo = () => {
  const [inputs, setInputs] = useState({
    mission: "",
    vision : ""
  })
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState(null);
  const [oldImgName, setOldImgName] = useState("")

  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState("")

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`/otherinfo`);
        console.log("fetch other info:::", response.data)
        setInputs(response.data);
        setOldImgName(response.data.imageUrl)
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };
    fetchProject();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setInputs((values) => {
      if (type === "file") {
        const uniqueFileNames = Array.from(files).map((file) => ({
          name: uuidv4() + "_" + file.name.replace(/\s+/g, ""),
          file,
        }));

        if (name === "image") {
          // Handle single image upload
          const imageFile = uniqueFileNames[0];
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(imageFile.file);
          return {
            ...values,
            slider: [...values.slider, uniqueFileNames[0].name ],
            image: event.target.files[0]
          };
        }

      } else {
        // Handle other input types
        return {
          ...values,
          [name]: value
        };
      }
    });
  };

  console.log('inputs>>>', inputs);
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    try {
      console.log("posting", inputs);
      const response = await axios.put(
        `/otherinfo`,
        inputs
      );

      if (inputs.image) {
        //uplaod the image
        const formData = new FormData();
        formData.append("image", inputs.image, inputs.imageUrl);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }

      if (response.status === 200 || response.status == 201) {
        toast.success("Location Uploaded Successfully !")
      } else if (response.status == 400) {
        toast.error("Location Name Required")
      }
    } catch (error) {
      setErr("failed to upload !")
      toast.error("Failed to update Data!")
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false)
    }

    console.log(inputs);
  };
  console.log("Home assential input<>>>>", inputs);

  return (
    <div className={styles.container}>
      <h2>Update Inforamtion</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        {/* image Section */}
        <div className={styles.formSection}>
        {/* Slider Images */}
       
        {/* <div className={styles.singleImgSctn}>
            <label>
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                name="image"
                checked={inputs.image}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                className={styles.previewImage}
              />
            )}
          </div>
    
    <div>
    {inputs?.slider?.map((s) => (
          <div className={styles.singleImgSctn}>
            <img
                src={`${server}/image/${s}`}
                alt="Preview"
                className={styles.previewImage}
              />
          </div>
        ))}

    </div> */}
          
        </div>

        <div className={styles.fieldContainer}>

          <div className={styles.formSection}>
            <label>
            Mission
              <textarea
                type="text"
                name="mission"
                value={inputs?.mission}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
            Vision
              <textarea
                type="text"
                name="vision"
                value={inputs?.vision}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
        </div>

        <button style={{ marginTop: "2vw", backgroundColor: loading && "#584db8" }} type="submit" className={styles.submitButton}>
          {loading ? "Loading...." : "Update Info"}
        </button>
      </form>
    </div>
  );
};

export default OthersInfo;
