import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import axios from "../../../api/axios";
import { useAppContext } from "../../../context/AuthProvider";

import "./Locations.css";
import "react-toastify/dist/ReactToastify.css";
import AlertModal from "../../Modals/AlertModal";
import AskModal from "../../Modals/AskModal";
import DeleteLocation from "../../CsrGalleryCURD/deleteCSRGallery/DeleteCsrGallery";
const server = process.env.REACT_APP_SERVER_URL

const CsrGallery = () => {
  const [csrGallery, setCsrGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [csrImgId, setCsrImgId] = useState("")

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`/csrgallery`);
      setCsrGallery(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchLocations();
  }, []);

  const onUpdateLocation = async () => {
    try {
      await fetchLocations();
    } catch (error) {
      setError(error);
    }
  };

  console.log("lcoation=>>", csrGallery)
  if (loading) {
    return <h3>Loading.....</h3>
  }

  console.log("location data::", csrGallery);
  console.log("env::server::", server)
  return (
    <>   
      <div className="page-header">
        <h2 className="title">CSR Gallery</h2>
        <div className="order-button-holder">
          <div className="filterItem">
          </div>
          <div className="filterItem">
            <NavLink to="/addNewCsrImg">
              <div className="addLocationBtn">+ Add Gallery Image</div>
            </NavLink>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className="order-table">
          <thead className="order-heading">
            <tr>
              <th></th>
              <th>Image Title</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {csrGallery.map((l) => (
              <tr key={"00"} className="order-row">
                <td></td>
                <td>{l.title}</td>
                <td>
                  <img
                    src={`${server}/image/${l.imgUrl}`}
                    alt="location image "
                    className=""
                  />
                </td>
                <td>
                <NavLink to={`/editCsrImg/${l.id}`}>
                    <button className="btn"> Edit Image</button>
                  </NavLink>
                </td>
                <td>
                  <button
                    className="btn"
                    onClick={() => { setOpenModal(true); setCsrImgId(l.id) }}
                  >
                    Delete Image
                  </button>
                </td>
              </tr>
            ))}

          </tbody>
          {openModal && (
            <AskModal open={openModal} onClose={() => setOpenModal(false)}>
              <DeleteLocation csrImgId={csrImgId} setOpenModal={setOpenModal}
                onUpdateLocation={onUpdateLocation} />
            </AskModal>
          )}
        </table>

      </div>
    </>
  );
};

export default CsrGallery;
