// AddNewProject.js
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import axios from "../../../api/axios";
import styles from "./EditProject.module.css";
import { useParams } from "react-router-dom";
import { computeHeadingLevel } from "@testing-library/react";
const server = process.env.REACT_APP_SERVER_URL

const MAX_FILE_SIZE = 800 * 1024; 

function EditProject() {
  const { id } = useParams()
  const inputRef = useRef(null);

  const [project, setProject] = useState({
    name: "",
    orderby: '',
    description: "",
    location: "",
    buildingHeight: "",
    noOfApartment: "",
    facing: "",
    landSize: "",
    noOfCarParking: "",
    apartmentSize: "",
    unitPerFloor: "",
    apartmentArrangement: "",
    noOfLifts: "",
    address: "",
    brochure: null,
    brochureName: "",
    image: null,
    imageName: "",
    image2: null,
    image2Name: "",
    images: [],
    images2: [],
    imagesName: [],
    florePlanImgs: [],
    map: { lat: 0, lon: 0 },
    feature: [],
    amenities: [],
    isLuxury: 0,
    isCurrentFeatured: 0
  });

  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("")

  const [previewImage, setPreviewImage] = useState(null);
  const [oldSingleImg, setOldSingleImg] = useState("")

  const [previewImage2, setPreviewImage2] = useState(null);
  const [oldSingleImg2, setOldSingleImg2] = useState("")
  
  // state for iamge gallery 
  const [previewImages, setPreviewImages] = useState([]);
  const [oldGalleryImg, setOldGalleryImg] = useState([])

  // state for flore Plan 
  const [previewImages2, setPreviewImages2] = useState([]);
  const [oldGalleryImg2, setOldGalleryImg2] = useState([])


  const fetchProject = async () => {
    try {
      const response = await axios.get(`/projects/${id}`);
      console.log("fetchbyId:::", response.data)
      setProject(response.data);
      setOldSingleImg(response.data.imageName)
      setOldSingleImg2(response.data.image2Name)
      setOldGalleryImg(response.data.imagesName)
      setOldGalleryImg2(response.data.florePlanImgs)
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  // fetch projects
  useEffect(() => {

    fetchProject();
  }, [id]);

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/location/child`);
      setLocation(response.data);
      // setLoading(false);
    } catch (error) {
      setErr(error);
      // setLoading(false);
    }
  };

  //fetch locations
  useEffect(() => {

    fetchLocations();
  }, []);


  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;
console.log("files::::", files);
    // Handle different types of input elements
    setProject((values) => {
      if (type === "checkbox") {
        return {
          ...values,
          [name]: checked,
        };
      } else if (type === "file") {

        const uniqueFileNames = Array.from(files).map((file) => ({
          name: uuidv4() + "_" + file.name.replace(/\s+/g, "-"),
          file,
        }));

        if (name === "brochure") {
          return {
            ...values,
            brochureName: uniqueFileNames[0].name,
            brochure: event.target.files[0]
          };
        } else if (name === "image") {
            if (files[0].size > MAX_FILE_SIZE) {
              // Some files were too large, show a warning
              toast.warn(
                "image exceeded the maximum size of 800 KB and were not selected."
              );
              // Clear input field after warning
              inputRef.current.value = null;
              return {
                ...values,
                image: null
              };
            }

          // Handle single image upload
          const imageFile = uniqueFileNames[0];
        
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImage(reader.result);
          };
          // Check if file exists before reading
          if (imageFile?.file) {
            reader.readAsDataURL(imageFile.file);
          } else {
            // If no file is selected, clear preview image
            setPreviewImage(null);
          }
          return {
            ...values,
            imageName: uniqueFileNames[0].name,
            image: event.target.files[0]
          };
        } else if (name === "image2") {
          if (files[0].size > MAX_FILE_SIZE) {
            // Some files were too large, show a warning
            toast.warn(
              "image exceeded the maximum size of 800 KB and were not selected."
            );
            // Clear input field after warning
            inputRef.current.value = null;
            return {
              ...values,
              image: null
            };
          }

        // Handle single image upload
        const imageFile = uniqueFileNames[0];
      
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage2(reader.result);
        };
        // Check if file exists before reading
        if (imageFile?.file) {
          reader.readAsDataURL(imageFile.file);
        } else {
          // If no file is selected, clear preview image
          setPreviewImage2(null);
        }
        return {
          ...values,
          image2Name: uniqueFileNames[0].name,
          image2: event.target.files[0]
        };
      } else if (name === "images") {

                    // Check if files exist and are within the size limit
                    const validFiles = Array.from(files).filter(
                      (file) => file.size <= MAX_FILE_SIZE
                    );
              
                    if (validFiles.length < files.length) {
                      // Some files were too large, show a warning
                      toast.warn(
                        "Some images exceeded the maximum size of 800 KB and were not selected."
                      );
                      // Clear input field after warning
                      inputRef.current.value = null;
                      return {
                        ...values,
                        images: null
                      };
                      
                    }

          // Clear the existing preview images array
          setPreviewImages([]);
          const newImages = [];
          const newImagesName = [];
          console.log("images::", uniqueFileNames);

          uniqueFileNames.forEach((image) => {
            // Check if the image already exists in the previewImages array
            const exists = newImages.some((preview) => preview === image.file);
            if (!exists) {
              newImages.push(image.file);
              newImagesName.push(image.name);
              const reader = new FileReader();
              reader.onload = () => {
                setPreviewImages((prevImages) => [...prevImages, reader.result]);
              };
              reader.readAsDataURL(image.file);
            }
          });

          return {
            ...values,
            images: newImages,
            imagesName: newImagesName,
          };
        } else if (name === "images2") {
          // Clear the existing preview images array
          setPreviewImages2([]);

          const uniqueFlorePlanFileNames = Array.from(files).map((file) => ({
            name: uuidv4() + "_" + file.name.replace(/\s+/g, "-"),
            file,
          }));

          const newImages = [];
          const newImagesName = [];
          console.log("images2::", uniqueFlorePlanFileNames);
          uniqueFlorePlanFileNames.forEach((image) => {
            // Check if the image already exists in the previewImages array
            const exists = newImages.some((preview) => preview === image.file);
            if (!exists) {
              newImages.push(image.file);
              newImagesName.push(image.name);
              const reader = new FileReader();
              reader.onload = () => {
                setPreviewImages2((prevImages) => [...prevImages, reader.result]);
              };
              reader.readAsDataURL(image.file);
            }
          });

          return {
            ...values,
            images2: newImages,
            florePlanImgs: newImagesName,
          };
        }
      } else {
        return {
          ...values,
          [name]:
            name === "feature" || name === "amenities"
              ? value.split(",")
              : value,
        };
      }
    });
  };

  const handleChangeMap = (coordinate, event) => {
    const value = event.target.value;
    setProject((values) => ({
      ...values,
      map: {
        ...values.map,
        [coordinate]: value,
      },
    }));
    console.log(project);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("going to submit:::", project);
    try {
      console.log("iamge2Check::", project.images2)
      setLoading(true)
      // create new inputs to keep old images
      console.log("check flore plan 1 img::", project.florePlanImgs)
      const newUpdateProduct = {
        ...project,
        imagesName: project.images ? [...project.imagesName, ...oldGalleryImg] : [...project.imagesName || []],
        florePlanImgs: project.images2 && Array.isArray(oldGalleryImg2) 
    ? [...project.florePlanImgs, ...oldGalleryImg2] 
    : [...project.florePlanImgs || []]
    };
    console.log("check flore plan 2 img::", project.florePlanImgs)
      console.log("newUpdateProduct:::", newUpdateProduct);

      const response = await axios.put(`/projects/${id}`, newUpdateProduct);
      console.log("Project updated successfully!");

      if (project.image ) {
        console.log("singlimg::", project.image);
        //uplaod the image
        const formData = new FormData();
        formData.append("image", project.image, project.imageName);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }
      if (project.image2 ) {
        console.log("singlimg::", project.image2);
        //uplaod the image
        const formData = new FormData();
        formData.append("image", project.image2, project.image2Name);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }

      // for gallery images 
      if (project.images && project.imagesName && project.imagesName.length > 0) {
        const formData = new FormData();
        if (Array.isArray(project.images)) {
          console.log("yes its images:::", project.images);
          //uplaod the image
          project.images.forEach((image, index) => {
            formData.append(`imagesName_${index}`, project.imagesName[index]);
            const fileName = project.imagesName[index];
            formData.append(`image`, image, fileName);
          });
        }

        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } 

      // for flore plan iamges 
      if (project.images2 && project?.florePlanImgs && project?.florePlanImgs?.length > 0) {
        const formData = new FormData();
        if (Array.isArray(project.images2)) {
          console.log("yes its images2:::", project.images2);
          //uplaod the image
          project.images2.forEach((image, index) => {
            formData.append(`imagesName_${index}`, project.florePlanImgs[index]);
            const fileName = project.florePlanImgs[index];
            formData.append(`image`, image, fileName);
          });
        }

        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("res:::", res)
      }

      if (response.status === 200 && oldSingleImg && oldSingleImg !== project.imageName) {
        const res = await axios.delete(`/image/${oldSingleImg}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("res", res)
        
      } else if (response.status === 200 && oldSingleImg2 !== project.image2Name) {
        const res = await axios.delete(`/image/${oldSingleImg2}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("res", res)
        
      } else if (response.status === 400) {
        toast.error("Something Wrong!, Reload the page")
      } 
      toast.success("Project Updated Successfully !")
      setLoading(false)
    } catch (error) {
      console.error("Error updating project:", error);
      setLoading(false)
      toast.error("Project is not Updated!")
      // Handle error, show error message, etc.
    }
  };

  const handleImageDelete = async (imgName, which) => {
    // console.log("imageNmae:::, which::", imgName, which);

    try {
      const updatedImagesName = project?.imagesName?.filter((s) => s !== imgName);
      const updatedFlorePlanImgs = project?.florePlanImgs?.filter((s) => s !== imgName);

      const updatedInputs = {
        ...project,
        imagesName: updatedImagesName,
        florePlanImgs: updatedFlorePlanImgs
      };

       const res = await axios.put(`/projects/${id}`, updatedInputs);
       console.log("deleted:::res:::", res);

      // Delete and navigate on success
      await axios
        .delete(`/image/${imgName}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          toast.success("Image Deleted Successfully !");
    
     
        })
        .catch((err) => {
          console.error("Error deleting image:", err);
          toast.error("Failed to delete image.");
         
        });
       
    } catch (err) {
      console.error("Error updating or deleting:", err);
      toast.error("Failed to update or delete.");

    }

  };

  const handleUpdateSlug = () => {
    const slug = project?.name?.toLowerCase().replace(/\s+/g, '-');
    setProject((values) => {
      return {
        ...values,
        slug: slug?.toLowerCase()
      }
    })
  }


  console.log("project>>>>>>", project);
  // console.log("feature>>::", project.feature);
  // console.log("ameniteis>>::", project.amenities);
  return (
    <div className={styles.container}>
      <h2>Update Project</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          {/* Project Details */}
          {/* name and Desctiption */}
          <div className={styles.formSection}>
            <label>
              Project Name:
              <input
                type="text"
                name="name"
                value={project.name}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Project Order: <span>(higher number show first)</span>
              <input
                type="text"
                name="orderby"
                value={project.orderby}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
              Project Slug:
              <input
                type="text"
                name="slug"
                value={project.slug}
                onChange={handleChange}
                className={styles.inputField}
                style={{textTransform: "lowercase"}}
              />
            </label>
            <span onClick={handleUpdateSlug} className={styles.slugBtn} > Generate Slug from Name 
            </span>
          </div>
          <div className={styles.formSection}>
            <label>
              Add to "Gateway To Luxury" 
              <span>(Give a big number for priority fist)</span>
              <input
                type="text"
                name="isLuxury"
                value={project.isLuxury}
                onChange={handleChange}
                className={styles.inputField}
                style={{textTransform: "lowercase"}}
              />
            </label>
            <label>
              Add to "Our Current Developments"
              <span>(Give big number for priority fist)</span>
              
              <input
                type="text"
                name="isCurrentFeatured"
                value={project.isCurrentFeatured}
                onChange={handleChange}
                className={styles.inputField}
                style={{textTransform: "lowercase"}}
              />
            </label>
         
          </div>
          <div className={styles.formSection}>
            <label>
              Project Description:
              <textarea
                name="description"
                value={project.description}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          {/* Type and Locaton  */}
          <div className={styles.formSection}>
            <label>
              Development Type:
              <select
                name="developmentType"
                value={project.developmentType}
                onChange={handleChange}
                className={styles.dropdown}
              >
                <option value="">Select</option>
                <option value="Current">Current</option>
                <option value="Upcoming">Upcoming</option>
                <option value="Completed">Completed</option>
              </select>
            </label>
            <label>
              Property Type
              <select
                name="propertyType"
                value={project.propertyType}
                onChange={handleChange}
                className={styles.dropdown}
              >
                <option value="">Select</option>
                <option value="Residential">Residential</option>
                <option value="Commercial">Commercial</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Rental">Rental</option>
                <option value="Rental">Land</option>
              </select>
            </label>
            <label>
              Project's Location
              <select
                name="location"
                value={project.location}
                onChange={handleChange}
                className={styles.dropdown}
              >
                <option value="">Select</option>
                {location.map((l) => (
                  <>      <option value={l.locationName}>{l.locationName}</option></>
                ))}

              </select>
            </label>
          </div>

          {/* Building and Apartment Details */}
          <div className={styles.formSection}>
            <label>
              Building Height: <span> (ex. 1500 Sqft)</span>
              <input
                type="text"
                name="buildingHeight"
                value={project.buildingHeight}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              No. of Apartments: <span> (ex. 30)</span>
              <input
                type="text"
                name="noOfApartment"
                value={project.noOfApartment}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Facing <span> (ex. North)</span>
              <input
                type="text"
                name="facing"
                value={project.facing}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              landSize
              <input
                type="text"
                name="landSize"
                value={project.landSize}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>

          <div className={styles.formSection}>
            <label>
              No. Of Car Parking
              <input
                type="text"
                name="noOfCarParking"
                value={project.noOfCarParking}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Apartment Size
              <input
                type="text"
                name="apartmentSize"
                value={project.apartmentSize}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              No. of Lift: <span> (ex. 5)</span>
              <input
                type="text"
                name="noOfLifts"
                value={project.noOfLifts}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Unit Per Floor
              <input
                type="text"
                name="unitPerFloor"
                value={project.unitPerFloor}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              apartment Arrangement
              <input
                type="text"
                name="apartmentArrangement"
                value={project.apartmentArrangement}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
              address
              <input
                type="text"
                name="address"
                value={project.address}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <div className={styles.mapSection}>
              <span>Input Map Data</span>
              <label>
                Latitude (lat)
                <input
                  type="text"
                  name="lat"
                  value={project?.map?.lat}
                  onChange={(e) => handleChangeMap("lat", e)}
                  className={styles.inputField}
                />
              </label>
              <label>
                Longitude (lon)
                <input
                  type="text"
                  name="lon"
                  value={project?.map?.lon}
                  onChange={(e) => handleChangeMap("lon", e)}
                  className={styles.inputField}
                />
              </label>
            </div>
          </div>
        </div>
        {/* Feature and amenities */}
        <div className={styles.formSection}>
          <label>
            Features <span> (ex. Write every feature with "," )</span>
            <textarea
              name="feature"
              value={project.feature}
              onChange={handleChange}
              className={`${styles.inputField} ${styles.feature}`}
            />
          </label>
          <label>
            Amenities <span> (ex. Write every Amenities with "," )</span>
            <textarea
              name="amenities"
              value={project.amenities}
              onChange={handleChange}
              className={`${styles.inputField} ${styles.amenities}`}
            />
          </label>
        </div>


        {/* image Section */}
        <div className={`${styles.formSection}`}>
          <div className={styles.singleImgSctn}>
            <label>
              Main Image (Single Image) <span>(Max File size 1mb)</span>
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                name="image"
                checked={project.image}
                onChange={handleChange}
                className={styles.fileInput}

              />
            </label>
            {previewImage ? (
              <img
                src={previewImage}
                alt="Preview"
                className={styles.previewImage}
              />
            ) : <img
              src={`${server}/image/${project.imageName}`}
              alt="Preview"
              className={styles.previewImage}
            />}
          </div>
          <div className={styles.singleImgSctn}>
            <label>
              Featured Image (Single Image)
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                name="image2"
                checked={project.image2}
                onChange={handleChange}
                className={styles.fileInput}

              />
            </label>
            {previewImage2 ? (
              <img
                src={previewImage2}
                alt="Preview"
                className={styles.previewImage}
              />
            ) : <img
              src={`${server}/image/${project.image2Name}`}
              alt="Preview"
              className={styles.previewImage}
            />}
          </div>
        </div>

        <div className={`${styles.formSection}`}>
          <div className={styles.multiImgSctn}>
            <label>
              Brochure<span>(supported file type PDF Only, Max size 1mb, )</span>
              <input
                type="file"
                multiple
                name="brochure"
                checked={project.brochure}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>

          </div>
        </div>
        
        {/* gallery Image Started  */}
        <div className={styles.formSectionn}>
          Gallery Images <span className={styles.notes} >(File size under 500kb, random size supported)</span>
          <div className={styles.galleryImgSection}>
            <label>
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                multiple
                name="images"
                ref={inputRef} 
                 accept="image/*"
                checked={project.images}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImages?.length > 0 ? (
              <div className={styles.previewGalleryImages}>
                {previewImages.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className={styles.multiPreviewImage}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.oldPreviewBox}>
                {project?.imagesName?.map((s) => (
                  <div className={styles.imgBox}>
                    <span onClick={() => handleImageDelete(s, "gallery")}>Delete</span>
                    <img
                      src={`${server}/image/${s}`}
                      alt="Preview"
                      className={styles.multiPreviewImage}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* gallery Image Ends  */}

            {/* flor plan section Started  */} 
            <div className={styles.formSectionn}>
          Flore Plan Images <span className={styles.notes} >(File size under 500kb, random size supported)</span>
          <div className={styles.galleryImgSection}>
            <label>
              <input
                type="file"
                multiple
                name="images2"
                checked={project.images2}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImages2?.length > 0 ? (
              <div className={styles.previewGalleryImages2}>
                {previewImages2.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className={styles.multiPreviewImage}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.oldPreviewBox}>
                { project && Array.isArray(project.florePlanImgs) &&  project?.florePlanImgs?.map((s) => (
                  <div className={styles.imgBox}>
                    <span onClick={() => handleImageDelete (s, "flore")}>Delete</span>
                    <img
                      src={`${server}/image/${s}`}
                      alt="Preview"
                      className={styles.multiPreviewImage}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* flor plan section end  */}
        <button type="submit" className={styles.submitButton}>
          {loading ? "Updating...." : "Upload Project"}
        </button>
        <span className={styles.error} >{err}</span>
      </form>
    </div>
  );
}

export default EditProject;
