import React from "react";
import ReactDom from "react-dom";

function AskModal({ open, children, onClose }) {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div className="overlay" />
      <div className="askModal">
        {children}
        
      </div>
    </>,
    document.getElementById("modal")
  );
}

export default AskModal;
