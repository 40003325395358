// AddNewProject.js
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import axios from "../../../api/axios";
import styles from "./EditLocation.module.css";
import { useParams } from "react-router-dom";
const server = process.env.REACT_APP_SERVER_URL

const MAX_FILE_SIZE = 800 * 1024;

function EditNews() {
  const { id } = useParams()
  const inputRef = useRef(null);

  const [singleLocationData, setSingleLocationData] = useState({
    title: "",
    orderby: '',
    des: "",
    imageUrl: "",
    image: null,
    slug: "",
    images: [],
    imageUrls: []
  });

  const [previewImage, setPreviewImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [oldGalleryImg, setOldGalleryImg] = useState([])

  const [oldImgName, setOldImgName] = useState("")

  const fetchNews = async () => {
    try {
      const response = await axios.get(`/news/${id}`);
      console.log("fetchbyId:::", response.data)
      setSingleLocationData(response.data);
      setOldImgName(response.data.imageUrl)
      setOldGalleryImg(response.data.imageUrls)
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  useEffect(() => {

    fetchNews();
  }, [id]);

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setSingleLocationData((values) => {



      if (name === "image") {
        const uniqueFileNames = Array.from(files).map(file => ({
          name: uuidv4() + '_' + file.name.replace(/\s+/g, ''),
          file
        }));

        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
        };
        const imageFile = uniqueFileNames[0];
        // Check if file exists before reading
        if (imageFile?.file) {
          reader.readAsDataURL(imageFile.file);
        } else {
          setPreviewImage(null);
        }
        return {
          ...values,
          imageUrl: uniqueFileNames[0].name,
          image: event.target.files[0]
        };
      } else if (name === "images") {
        const uniqueFileNames = Array.from(files).map(file => ({
          name: uuidv4() + '_' + file.name.replace(/\s+/g, ''),
          file
        }));

        // Check if files exist and are within the size limit
        const validFiles = Array.from(files).filter(
          (file) => file.size <= MAX_FILE_SIZE
        );

        if (validFiles.length < files.length) {
          // Some files were too large, show a warning
          toast.warn(
            "Some images exceeded the maximum size of 800 KB and were not selected."
          );
          // Clear input field after warning
          inputRef.current.value = null;
          return {
            ...values,
            images: null
          };

        }

        // Clear the existing preview images array
        setPreviewImages([]);
        const newImages = [];
        const newImagesName = [];
        console.log("images::", uniqueFileNames);

        uniqueFileNames.forEach((image) => {
          // Check if the image already exists in the previewImages array
          const exists = newImages.some((preview) => preview === image.file);
          if (!exists) {
            newImages.push(image.file);
            newImagesName.push(image.name);
            const reader = new FileReader();
            reader.onload = () => {
              setPreviewImages((prevImages) => [...prevImages, reader.result]);
            };
            reader.readAsDataURL(image.file);
          }
        });

        return {
          ...values,
          images: newImages,
          imageUrls: newImagesName,
        };
      }
      else {
        // Handle other input types
        return {
          ...values,
          [name]: value,
        };
      }
    });
  };

  const handleUpdateSlug = () => {
    const slug = singleLocationData.title.toLowerCase().replace(/\s+/g, '-');
    setSingleLocationData((values) => {
      return {
        ...values,
        slug: slug.toLowerCase()
      }
    })
  }

  const handleImageDelete = async (imgName, which) => {
    console.log("imageNmae:::, which::", imgName, which);

    try {
      const updatedImagesName = singleLocationData?.imageUrls?.filter((s) => s !== imgName);

      const updatedInputs = {
        ...singleLocationData,
        imageUrls: updatedImagesName
      };

      await axios.put(`/news/${id}`, updatedInputs);

      // Delete and navigate on success
      await axios
        .delete(`/image/${imgName}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          toast.success("Image Deleted Successfully !");
          fetchNews();
        })
        .catch((err) => {
          console.error("Error deleting image:", err);
          toast.error("Failed to delete image.");
        });
    } catch (err) {
      console.error("Error updating or deleting:", err);
      toast.error("Failed to update or delete.");
    }
  };

  console.log(singleLocationData);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // console.log("posting", singleLocationData)

      const newUpdateNews = {
        ...singleLocationData,
        imageUrls: singleLocationData.images ? [...singleLocationData.imageUrls, ...oldGalleryImg] : [...singleLocationData.imageUrls || []],
    };

      // Send formData to your server using axios or your preferred HTTP client
      const response = await axios.put(`/news/${id}`, newUpdateNews);

      if (singleLocationData.image) {
        console.log("singlimg::", singleLocationData.image);
        //uplaod the image
        const formData = new FormData();
        formData.append("image", singleLocationData.image, singleLocationData.imageUrl);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }

      // for gallery images 
      if (singleLocationData.images && singleLocationData.imageUrls && singleLocationData.imageUrls.length > 0) {
        const formData = new FormData();
        if (Array.isArray(singleLocationData.images)) {
          console.log("yes its images:::", singleLocationData.images);
          //uplaod the image
          singleLocationData.images.forEach((image, index) => {
            formData.append(`imagesName_${index}`, singleLocationData.imageUrls[index]);
            const fileName = singleLocationData.imageUrls[index];
            formData.append(`image`, image, fileName);
          });
        }

        console.log("ready to upload image::", formData);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }


      // if image change then delete old image 
      if (response.status === 200 && oldImgName !== singleLocationData.imageUrl) {
        const res = await axios.delete(`/image/${oldImgName}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        console.log("res", res)
      }

      if (response.status == 200) {
        toast.success("Location Updated Successfully !")
      } else if (response.status == 400) {
        toast.error("Location is not Updated!")
      }

      console.log("Response:", response.data);
    } catch (error) {
      console.log("error:::", error);
      toast.error("Unexprected Error Found !")
      console.error("Error submitting form:", error);
    }

    // console.log(singleLocationData);
  };
  console.log("singleLocationData:::--::", singleLocationData);

  return (
    <div className={styles.container}>
      <h2>Update News/Events</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          {/* Project Details */}
          {/* name and Desctiption */}
          <div className={styles.formSection}>
            <label>
              News/Event Title
              <input
                type="text"
                name="title"
                value={singleLocationData?.title}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              OrderBy <span>(Higher Number Show First)</span>
              <input
                type="text"
                name="orderby"
                value={singleLocationData?.orderby}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
              News/Event Slug
              <input
                type="text"
                name="slug"
                value={singleLocationData?.slug}
                onChange={handleChange}
                className={`${styles.inputField} ${styles.lowerCase}`}
              />
            </label>
            <span onClick={handleUpdateSlug} className={styles.slugBtn} > Generate Slug from Title
            </span>
          </div>
          <div className={styles.formSection}>
            <label>
              News/Event Title
              <textarea
                type="text"
                name="des"
                value={singleLocationData?.des}
                onChange={handleChange}
                className={`${styles.inputField} ${styles.textArea}`}
              />
            </label>
          </div>
          {/* image Section */}
          <div className={styles.formSection}>
            <div className={styles.singleImgSctn}>
              <label>
                News/Event Image (Single Image)
                <input
                  // style={{backgroundColor: "gray"}}
                  type="file"
                  name="image"
                  checked={singleLocationData.image}
                  onChange={handleChange}
                  className={styles.fileInput}
                />
              </label>
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="Preview"
                  className={styles.previewImage}
                />
              ) : <img
                src={`${server}/image/${singleLocationData.imageUrl}`}
                alt="Preview"
                className={styles.previewImage}
              />}
            </div>
          </div>


          {/* gallery Image Started  */}
          <div className={styles.formSectionn}>
            Gallery Images <span className={styles.notes} >(File size under 600kb, random size supported)</span>
            <div className={styles.galleryImgSection}>
              <label>
                <input
                  // style={{backgroundColor: "gray"}}
                  type="file"
                  multiple
                  name="images"
                  ref={inputRef}
                  accept="image/*"
                  checked={singleLocationData.images}
                  onChange={handleChange}
                  className={styles.fileInput}
                />
              </label>
              {previewImages?.length > 0 ? (
                <div className={styles.previewGalleryImages}>
                  {previewImages.map((preview, index) => (
                    <img
                      key={index}
                      src={preview}
                      alt={`Preview ${index + 1}`}
                      className={styles.multiPreviewImage}
                    />
                  ))}
                </div>
              ) : (
                <div className={styles.oldPreviewBox}>
                  {singleLocationData && Array.isArray(singleLocationData.imageUrls) && singleLocationData?.imageUrls?.map((s) => (
                    <div className={styles.imgBox}>
                      <span onClick={() => handleImageDelete(s, "gallery")}>Delete</span>
                      <img
                        src={`${server}/image/${s}`}
                        alt="Preview"
                        className={styles.multiPreviewImage}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {/* gallery Image Ends  */}

        </div>
        <button type="submit" className={styles.submitButton}>
          Upload News/Event
        </button>
      </form>
    </div>
  );
}

export default EditNews;
