import React, { useEffect, useState } from "react";

import axios from "../../api/axios";

const AdminList = () => {
  const [adminList, setAdminList] = useState();

  useEffect(() => {
    const getAdminData = async () => {
      try {
        const response = await axios.get("/admin");
        setAdminList(response.data);
      } catch (error) {
        console.log("Axios Error: ", error);
      }
    };
    getAdminData();
  }, []);
  return (
    <div className="admin-table-wrapper">
      <table className="admin-table">
        <tbody>
          <tr className="table-row border-bottom">
            <th>Name</th>
            <th>Role</th>
            <th>Contact no.</th>
            <th>Email</th>
            <th>Outlet</th>
          </tr>
          {adminList &&
            adminList?.map((admin) => (
              <tr className="table-row ">
                <td>{admin.name}</td>
                <td>{admin.role}</td>
                <td>{admin.phone}</td>
                <td>{admin.email}</td>
                {/* <td>{admin?.outlets.toString()}</td> */}
                <td>{admin?.outlets?.map((outlet) => (<>{ outlet.name}<br /></>))}</td>
                <td>
                  <button className="deleteFoodButton">
                    <img src="/icons/Delete.svg" />
                  </button>
                  <button
                    //   onClick={() => {
                    //     navigate(`/item/edit/${item._id}`);
                    //   }}
                    className="editFoodButton"
                  >
                    <img src="/icons/Edit.svg" />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminList;
