// AddNewProject.js
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import axios from "../../../api/axios";
import styles from "./EditLocation.module.css";
import { useParams } from "react-router-dom";
const server = process.env.REACT_APP_SERVER_URL

function EditLocation() {
  const { id } = useParams()
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false)
  const [singleLocationData, setSingleLocationData] = useState({
    locationName: "",
    orderby: '',
    imageName: "",
    image: null,
    parentid: ""

  });
  const [previewImage, setPreviewImage] = useState(null);
  const [oldImgName, setOldImgName] = useState("")

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`/location/${id}`);
        console.log("fetchbyId:::", response.data)
        setSingleLocationData(response.data);
        setOldImgName(response.data.imageName)
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };
    fetchProject();
  }, [id]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`/location/parent`);
        setLocation(response.data);
        setLoading(false);
      } catch (error) {
        
        setLoading(false);
      }
    };
    fetchLocations();
  }, []);


  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setSingleLocationData((values) => {

      if (name === "image") {
        const uniqueFileNames = Array.from(files).map(file => ({
          name: uuidv4() + '_' + file.name.replace(/\s+/g, ''),
          file
        }));

        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
        };
        const imageFile = uniqueFileNames[0];
        // Check if file exists before reading
        if (imageFile?.file) {
          reader.readAsDataURL(imageFile.file);
        } else {
          setPreviewImage(null);
        }
        return {
          ...values,
          imageName: uniqueFileNames[0].name,
          image: event.target.files[0]
        };
      }
      else {
        // Handle other input types
        return {
          ...values,
          [name]: value,
        };
      }
    });
  };


  console.log(singleLocationData);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // console.log("posting", singleLocationData)
      // Send formData to your server using axios or your preferred HTTP client
      const response = await axios.put(`/location/${id}`, singleLocationData);

      if (singleLocationData.image) {
        console.log("singlimg::", singleLocationData.image); 
        //uplaod the image
        const formData = new FormData();
        formData.append("image", singleLocationData.image, singleLocationData.imageName);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }

      // if image change then delte old image 
      if (response.status == 200 && oldImgName !== singleLocationData.imageName) {
        const res = await axios.delete(`/image/${oldImgName}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        console.log("res", res)
      }

      if (response.status == 200) {
        toast.success("Location Updated Successfully !")
      } else if (response.status == 400) {
        toast.error("Location is not Updated!")
      }

      console.log("Response:", response.data);
    } catch (error) {
      console.log("error:::", error);
      toast.error("Unexprected Error Found !")
      console.error("Error submitting form:", error);
    }
    console.log(singleLocationData);
  };

  console.log("location::", location);
  return (
    <div className={styles.container}>
      <h2>Update Project</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          {/* Project Details */}
          {/* name and Desctiption */}
          <div className={styles.formSection}>
            <label>
              Project Name:
              <input
                type="text"
                name="locationName"
                value={singleLocationData?.locationName}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
   
          </div>
          <div className={styles.formSection}>
  
            <label>
              Select Parent Location:
              <select
                name="parentid"
                value={singleLocationData.parentid}
                onChange={handleChange}
                className={styles.dropdown}
              >
                <option value="">Select</option>
                {/* Map over parent locations */}
                {location.map((location) => (
                  <option key={location.locationId} value={location.locationId}>{location.locationName}</option>
                ))}
              </select>
            </label>

            <label>
              OrderBy: <span>(Big Number show first)</span>
              <input
                type="text"
                name="orderby"
                value={singleLocationData?.orderby}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>


          </div>
          {/* image Section */}
          <div className={styles.formSection}>
            <div className={styles.singleImgSctn}>
              <label>
                Location Image (Single Image)
                <input
                  // style={{backgroundColor: "gray"}}
                  type="file"
                  name="image"
                  checked={singleLocationData.image}
                  onChange={handleChange}
                  className={styles.fileInput}
                />
              </label>
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="Preview"
                  className={styles.previewImage}
                />
              ) : <img
                src={`${server}/image/${singleLocationData.imageName}`}
                alt="Preview"
                className={styles.previewImage}
              />}
            </div>
          </div>
        </div>
        <button type="submit" className={styles.submitButton}>
          Upload Location
        </button>
      </form>
    </div>
  );
}

export default EditLocation;
