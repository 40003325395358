// AddNewClient.js
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import axios from "../../../api/axios";
import styles from "./AddNewReview.module.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AddNewReview() {
  const navigate = useNavigate(); 
  const [inputs, setInputs] = useState({
    name: "",
    designation: "",
    comments: "",
    imageUrl: "",
    image: null,
  })

  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState("")
  const [success, setSuccess] = useState("")


  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setInputs((values) => {
      if (type === "file") {
        const uniqueFileNames = Array.from(files).map((file) => ({
          name: uuidv4() + "_" + file.name.replace(/\s+/g, ""),
          file,
        }));

        if (name === "image") {
          // Handle single image upload
          const imageFile = uniqueFileNames[0];
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(imageFile.file);
          return {
            ...values,
            imageUrl: uniqueFileNames[0].name,
            image: event.target.files[0]
          };
        }

      } else {
        // Handle other input types
        return {
          ...values,
          [name]: value
        };
      }
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    try {
      console.log("posting", inputs);
      const response = await axios.post(
        `/review`,
        inputs
      );

      if (inputs.image) {
        //uplaod the image
        const formData = new FormData();
        formData.append("image", inputs.image, inputs.imageUrl);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success("Client Added Successfully !")
        
      } else if (response.status === 400) {
        toast.error("client Name Required")
      }

    } catch (error) {
      setErr("failed to upload !")
      toast.error("Failed to upload the project!")
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false)

      setTimeout(() => {
        navigate("/clientReviews"); 
      }, 1500); // 1.5 seconds delay
    }

    console.log(inputs);
  };

  console.log("inpt data", inputs);

  return (
    <div className={styles.container}>
      <h2>Add New Client's Review</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.formSection}>
            <label>
              Client Name:
              <input
                type="text"
                name="name"
                value={inputs.name}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Client Designation:
              <input
                type="text"
                name="designation"
                value={inputs.designation}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
              Client Comments:
              <textarea
                type="text"
                name="comments"
                value={inputs.comments}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
           
          </div>
        </div>

        {/* image Section */}
        <div className={styles.formSection}>
          <div className={styles.singleImgSctn}>
            <label>
              Client Image (Single Image)
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                name="image"
                checked={inputs.image}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                className={styles.previewImage}
              />
            )}
          </div>
        </div>
        <button style={{ marginTop: "2vw", backgroundColor: loading && "#584db8" }} type="submit" className={styles.submitButton}>
          {loading ? "Loading...." : "Add"}
        </button>
      </form>
    </div>
  );
}

export default AddNewReview;
