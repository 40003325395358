import { React, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

import "./Login.css";


const Login = () => {
  const navigate = useNavigate();
  const { setAuth, setToken, } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();

  const [email, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    userRef.current.focus();
  }, []);
  
  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

console.log("user:emai:::", email, "user pass", pwd)

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(user, pwd);
    try {
      // login commentts
      setIsLoading(true)
      const response = await axios.post("/login",
        JSON.stringify({ email: email, password: pwd}),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true 
        }
      );

      console.log("Auth Response =>>", response?.data);

         const { token, user } = response.data; 
      // Update auth context 
      setAuth(user);  
      // Set token separately 
      setToken(token); 
      
      localStorage.setItem("token", response.data.token);
      setUser("");
      setPwd("");
      setSuccess(true);
      setIsLoading(false)
      navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Username or Password is not corrected");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      // errRef.current.focus();
    }
  };


  return (
    <>
      {success ? (
        <section>
          <h1>You are logged in!</h1>
          <br />
          <p>
            <a href="#">Go to Home</a>
          </p>
        </section>
      ) : (
        <section>
          {/* Status Message */}
          {/* <p
            ref={errRef}
            className={errMsg ? "errMsg" : "offScreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p> */}

          <div className="login_page">
            <div className="lefty">
              <div className="container">
                <h2>Log in</h2>
                <p>Admin</p>
                <form onSubmit={handleSubmit}>
                  <div className="mail">
                    <label>
                      Email
                      <br />
                      <input
                        type="email"
                        id="email"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        value={email}
                        name="email"
                        required="required"
                        placeholder="someone@example.com"
                      />
                    </label>
                  </div>
                  <div className="pass">
                    <label>
                      Password
                      <br />
                      <input
                        type="password"
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        name="password"
                        required="required"
                        placeholder="************"
                      />
                    </label>
                  </div>  
                  <div className="btnSection" >
                  {errMsg && <span>{errMsg}</span>}
                  <button>{isLoading ? "Login..." : "Log in"}</button>
                    </div>             
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;
