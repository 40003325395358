import React from "react";
import { Navigate, Route } from "react-router-dom";

import { useAppContext } from "../context/AuthProvider";

const PrivateRoute = ({ element, children }) => {
  const { auth } = useAppContext();

  if (auth.role) {
    return children;
  }
  return <Navigate to="/login" replace />;
  return children;
};

export default PrivateRoute;
