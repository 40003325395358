import { useEffect, useRef, useState } from "react";
import { AiFillAppstore, AiOutlineProject } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

// import { useAppContext } from "react-icons/tfi";
import { useAppContext } from "../../context/AuthProvider";

import "./Sidebar.css";

export default function Sidebar() {
  const [open, setOpen] = useState(false);
  const { toggleLeftSidebar, setToggleLeftSidebar, auth } = useAppContext();
  const navigate = useNavigate();

  const logoutFunction = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    window.location.reload();
  };

  return (
    <>
      <div className={`sidebar ${toggleLeftSidebar ? "active" : ""}`}>
        <div className="main-logo">
          <Link to="/">
            {/* main logo */}
            <img src="/images/logo-2.png" alt="toggler-left" />
          </Link>
        </div>

        <div className="sidebar-items">
          <NavLink
            to="/form"
            className={open ? "sidebar-item open" : "sidebar-item"}
            onClick={() => {
              setOpen(!open);
            }}
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Form Data</div>
          </NavLink>
          <NavLink
            to="/projects"
            className={open ? "sidebar-item open" : "sidebar-item"}
            onClick={() => {
              setOpen(!open);
            }}
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Projects</div>
          </NavLink>
          <NavLink
            to="/locations"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Locations</div>
          </NavLink>
          <NavLink
            to="/newsNevents"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">News & Events</div>
          </NavLink>
          <NavLink
            to="/clients"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Clients/Partner</div>
          </NavLink>
          <NavLink
            to="/clientReviews"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Clients Review</div>
          </NavLink>
          <NavLink
            to="/landowner"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Landowner Review</div>
          </NavLink>
          <NavLink
            to="/secureTeam"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Secure Team</div>
          </NavLink>
          <NavLink
            to="/sustainability"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Sustainability Page</div>
          </NavLink>

          <NavLink
            to="/homepageassential"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Home Pages Assentials</div>
          </NavLink>
          {/* <NavLink
            to="/about"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">About Page</div>
          </NavLink> */}
         
          <NavLink
            to="/managements"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Managements</div>
          </NavLink>
          <NavLink
            to="/contact"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Contacts Info</div>
          </NavLink>
          <NavLink
            to="/csr"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">CSR Page</div>
          </NavLink>
          <NavLink
            to="/csrgallery"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">CSR Gallery</div>
          </NavLink>
          <NavLink
            to="/story"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Brand Story</div>
          </NavLink>
          <NavLink
            to="/othersInfo"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Others Info</div>
          </NavLink>
          {/* <NavLink
            to="/footer"
            className="sidebar-item plain"
            activeclassname="active"
          >
            <div className="icon">
              <AiFillAppstore />
            </div>
            <div className="titles">Footer info</div>
          </NavLink> */}

<div onClick={logoutFunction} className="logout">
            Log out
          </div>

        </div>   
      </div>
    </>
  );
}
