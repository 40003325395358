import React from "react";

import { useAppContext } from "../../context/AuthProvider";
import Sidebar from "../Sidebar/Sidebar";
import OutletTopbar from "../Topbar/OutletTopbar";

import "./layout.css";

const Layout = ({ children }) => {
  const { auth } = useAppContext();
  const isAdmin = auth?.role?.length > 0;
console.log("isAdmin::", auth.role)
  // return (
  //   <>

  //       <div className="layout">
  //         <div className="layout-sidebar">
  //           <Sidebar />
  //         </div>
  //         <div className="layout-main-container">
  //           <OutletTopbar />
  //           <div className="layout-dashboard-contents">{children}</div>
  //         </div>
  //       </div>
  //   </>
  // );
  
  // old with login condition
  return (
    <>
      {isAdmin ? (
        <div className="layout">
          <div className="layout-sidebar">
            <Sidebar />
          </div>
          <div className="layout-main-container">
            <OutletTopbar />
            <div className="layout-dashboard-contents">{children}</div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Layout;
