import React, { useState } from 'react';
import styles from './FooterInfo.module.css';

const FooterInfo = () => {
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    const imageList = files.map((file) => URL.createObjectURL(file));
    setPreviewImages((prevImages) => [...prevImages, ...imageList]);

    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newPreviews = [...previewImages];
    newPreviews.splice(index, 1);
    setPreviewImages(newPreviews);
  };

  return (
    <div className={styles.container}>
      <h2>Update Footer Data</h2>
      <div className={styles.gallery}>
        {previewImages.map((preview, index) => (
          <div className={styles.imageContainer} key={index}>
            <img src={preview} alt={`Slider ${index}`} />
            <button
              className={styles.deleteButton}
              onClick={() => handleDeleteImage(index)}
            >
              Delete
            </button>
            <button className={styles.changeButton}>Change</button>
          </div>
        ))}
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        multiple
        style={{ display: 'none' }}
        id="imageInput"
      />
      <label htmlFor="imageInput" className="btn">
        Add New Image
      </label>
    </div>
  );
};

export default FooterInfo;
