// AddNewClient.js
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import axios from "../../../api/axios";
import styles from "./AddMaterials.module.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AddNewMaterials() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    name: "",
    details: ""
  })

  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState("")
  const [success, setSuccess] = useState("")

  const handleChange = (event) => {
    const { name, value } = event.target;

    setInputs((values) => {
        return {
          ...values,
          [name]: value
        };
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    try {
      console.log("posting", inputs);
      const response = await axios.post(
        `/materials`,
        inputs
      );


      if (response.status === 200 || response.status === 201) {
        toast.success("Client Added Successfully !")

      } else if (response.status === 400) {
        toast.error("client Name Required")
      }

    } catch (error) {
      setErr("failed to upload !")
      toast.error("Failed to upload the project!")
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false)

      setTimeout(() => {
        navigate("/sustainability");
      }, 1500); // 1.5 seconds delay
    }

    console.log(inputs);
  };

  console.log("inpt data", inputs);

  return (
    <div className={styles.container}>
      <h2>Add New Materials Specification</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.formSection}>
            <label>
            Material Name:
              <input
                type="text"
                name="name"
                value={inputs.name}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
            Details:
              <textarea
                type="text"
                name="details"
                value={inputs.details}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
        </div>

        <button style={{ marginTop: "2vw", backgroundColor: loading && "#584db8" }} type="submit" className={styles.submitButton}>
          {loading ? "Loading...." : "Add"}
        </button>
      </form>
    </div>
  );
}

export default AddNewMaterials;
