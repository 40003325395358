import { useState } from "react";
import axios from "../../../api/axios"


const DeleteNews = ({ newsId, imageUrl,setOpenModal, onUpdateLocation  }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    console.log("newsId:::", newsId);
    const handleYesClick = async () => {
        setLoading(true);
        
        try {
            const res = await axios.delete(`/news/${newsId}`);
            console.log("delete response:", res);
            // Close the modal after successful deletion
            if (res.status === 200 ) {
                const res = await axios.delete(`/image/${imageUrl}`, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
        
                });
                console.log("res", res)
              }
            setOpenModal(false);
            onUpdateLocation() 
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseClick = () => {
        setOpenModal(false); 
    };

    return (
        <>
          <h4> Are you want to delete?</h4> 
            <div className="ask-alert-bottons">
                <button className="ask-close-btn" onClick={handleYesClick}>Yes</button>
                <button className="ask-close-btn" onClick={handleCloseClick}>Cancel</button>
            </div>
        </>
    )
}

export default DeleteNews;
