import React from "react";
import { Navigate } from "react-router-dom";

import { useAppContext } from "../context/AuthProvider";

const SuperAdminRoute = ({ children }) => {
  const { auth } = useAppContext();

  if ( auth.role === "SuperAdmin") {
    return children;
  }
  return <Navigate to="/login" replace />;
};

export default SuperAdminRoute;