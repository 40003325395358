import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import axios from "../../../api/axios";
import { useAppContext } from "../../../context/AuthProvider";

import "./OurStory.css";
import "react-toastify/dist/ReactToastify.css";
import AlertModal from "../../Modals/AlertModal";
import AskModal from "../../Modals/AskModal";
const server = process.env.REACT_APP_SERVER_URL

const OurStory = () => {
  const [csrGallery, setCsrGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [csrImgId, setCsrImgId] = useState("")

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`/story`);
      setCsrGallery(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchLocations();
  }, []);

  const onUpdateLocation = async () => {
    try {
      await fetchLocations();
    } catch (error) {
      setError(error);
    }
  };

  console.log("our story::=>>", csrGallery)
  if (loading) {
    return <h3>Loading.....</h3>
  }

  return (
    <>
      <div className="page-header">
        <h2 className="title">Brand Story</h2>
        <div className="order-button-holder">

        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className="order-table">
          <thead className="order-heading">
            <tr>
              <th></th>
              <th>Title</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {csrGallery.map((l) => (
              <tr key={"00"} className="order-row">
                <td></td>
                <td>{l.title}</td>
                <td>
                  <img
                    src={`${server}/image/${l.imgUrl}`}
                    alt="location image "
                    className=""
                  />
                </td>
                <td>
                  <NavLink to={`/editStory/${l.id}`}>
                    <button className="btn"> Edit Story</button>
                  </NavLink>
                </td>
        
              </tr>
            ))}

          </tbody>
        </table>

      </div>
    </>
  );
};

export default OurStory;
