import React, { useEffect, useState } from 'react';
import styles from './SecureTeam.module.css';
import { toast } from "react-toastify";
import axios from "./../../../api/axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
const server = process.env.REACT_APP_SERVER_URL

const SecureTeam = () => {
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    imgUrls: [],
    images: null,
  })
  
  const [previewImages, setPreviewImages] = useState(null);
  const [oldImgUrls, setOldImgUrls] = useState(null)

  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState("")


  const fetchProject = async () => {
    try {
      const response = await axios.get(`/team`);
      console.log("teamFetch:::", response.data[0])
      setInputs(response.data[0]);
      setOldImgUrls(response.data[0].imgUrls || []);
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  useEffect(() => {

    fetchProject();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setInputs((values) => {
      if (type === "file") {
        const uniqueFileNames = Array.from(files).map((file) => ({
          name: uuidv4() + "_" + file.name.replace(/\s+/g, ""),
          file,
        }));

        setPreviewImages([]);
        const newImages = [];
        const newImagesName = [];

        uniqueFileNames.forEach((image) => {
          // Check if the image already exists in the previewImages array
          const exists = newImages.some((preview) => preview === image.file);
          if (!exists) {
            newImages.push(image.file);
            newImagesName.push(image.name);
            const reader = new FileReader();
            reader.onload = () => {
              setPreviewImages((prevImages) => [...prevImages, reader.result]);
            };
            reader.readAsDataURL(image.file);
          }
        });

        // Handle other input types
        return {
          ...values,
          images: newImages,
          imgUrls: newImagesName,
        };
      }
    });
  };
  console.log('inputs>>>', inputs);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const newInputs = {
        ...inputs,
        imgUrls: [...oldImgUrls, ...inputs.imgUrls],
      };

      const response = await axios.put(`/team`, newInputs);
  
      const formData = new FormData();
      if (Array.isArray(inputs.images)) {
        //uplaod the image
        inputs.images.forEach((image, index) => {
          formData.append(`imagesName_${index}`, inputs.imgUrls[index]);
          const fileName = inputs.imgUrls[index];
          formData.append(`image`, image, fileName);
        });
      }

      const res = await axios.post("/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status == 201) {
        toast.success("Location Uploaded Successfully !");
        
        setTimeout(() => {
          window.location.reload();
        }, 2000); 

      } else if (response.status == 400) {
        toast.error("Location Name Required");
      }
    } catch (error) {
      setErr("failed to upload !");
      toast.error("Failed to update Data!");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
   
    }
  };




  const handleImageDelete = async (imageName) => {
    console.log("imageNmae:::", imageName);
    try {
      const updatedSlider = inputs?.imgUrls?.filter((s) => s !== imageName);

      const updatedInputs = {
        ...inputs,
        imgUrls: updatedSlider,
      };

      await axios.put(`/team`, updatedInputs);

      // Delete and navigate on success
      await axios
        .delete(`/image/${imageName}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          toast.success("Image Deleted Successfully !");
          fetchProject();
        })
        .catch((err) => {
          console.error("Error deleting image:", err);
          toast.error("Failed to delete image.");
        });
    } catch (err) {
      console.error("Error updating or deleting:", err);
      toast.error("Failed to update or delete.");
    }
  };



  console.log("Secure Team input<>>>>", inputs);

  return (
    <div className={styles.container}>
      <h2>Update SecureTeam Page Data</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
      
        <div className={styles.fieldContainer}>

          <div className={styles.formSection}>
            <label>
             Main Title
              <input
                type="text"
                name="title"
                value={inputs.title }
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
              Description
              <textarea
                type="text"
                name="description"
                value={inputs.description}
                onChange={handleChange}
                className={styles.textarea}
              />
            </label>
          </div>

        {/* image Section */}
        <div className={styles.formSection}>
          Slider Images <span className={styles.notes} >(File size under 1 mb, Image Dimention 700*400 px )</span> 
          <div className={styles.singleImgSctn}>
            <label>
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                multiple
                name="images"
                checked={inputs.images}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImages?.length > 0 ? (
              <div className={styles.previewGalleryImages}>
                {previewImages.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className={styles.multiPreviewImage}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.oldPreviewBox}>
                {inputs?.imgUrls?.map((s) => (
                  <div className={styles.imgBox}>
                    <span onClick={() => handleImageDelete(s)}>Delete</span>
                    <img
                      src={`${server}/image/${s}`}
                      alt="Preview"
                      className={styles.multiPreviewImage}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        </div>
        <button style={{ marginTop: "2vw", backgroundColor: loading && "#584db8" }} type="submit" className={styles.submitButton}>
          {loading ? "Loading...., Please wait" : "Update Info"}
        </button>
      </form>
    </div>
  );
};

export default SecureTeam;
