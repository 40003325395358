import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import axios from "../../../api/axios";
import { useAppContext } from "../../../context/AuthProvider";

import style from "./newsNevent.module.css";
import "react-toastify/dist/ReactToastify.css";
import AlertModal from "../../Modals/AlertModal";
import AskModal from "../../Modals/AskModal";
import DeleteNews from "../../NewsNeventCURD/deleteNewsNevent/DeleteNewsNevent";
const server = process.env.REACT_APP_SERVER_URL

const NewNevents = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [newsId, setNewsId] = useState("")
  const [imageUrl, setImageUrl] = useState("")

  const fetchLocations = async () => {
    try {
      const response = await axios.get(`/news`);
      setNews(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchLocations();
  }, []);

  const onUpdateLocation = async () => {
    try {
      await fetchLocations();
    } catch (error) {
      setError(error);
    }
  };

  console.log("lcoation=>>", news)
  if (loading) {
    return <h3>Loading.....</h3>
  }
  console.log("location data::", news);
  console.log("env::server::", server)
  return (
    <>
      <div className={style.pageHeader}>
        <h2 className={style.title}>Manage all News and Events</h2> 
        <div className={style['order-button-holder']}>
          <div className={style['filterItem']}>
          </div>
          <div className={style['filterItem']}>
            <NavLink to="/AddNews">
              <div className="addLocationBtn">+ Add News</div>
            </NavLink>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table className={style["order-table"]}>
          <thead className={style["order-heading"]}>
            <tr>
              <th></th>
              <th>Title</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {news.map((n) => (
              <tr key={"00"} className="order-row">
                <td></td>
                <td>{n.title}</td>
                <td>
                  <img
                    src={`${server}/image/${n.imageUrl ? n.imageUrl : "2a4211a7-73dc-43d6-b85f-1994cf22061a_nice-nature-image.jpg"}`}
                    alt={news?.title}
                    className=""
                   
                  />
                </td>
                <td>
                <NavLink to={`/editNews/${n.newsId}`}>
                    <button className="btn"> Edit</button>
                  </NavLink>

              
                </td>
                <td>
                  <button
                    className="btn"
                    onClick={() => { setOpenModal(true); setNewsId(n.newsId); setImageUrl(n.imageUrl) }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}

          </tbody>
          {openModal && (
            <AskModal open={openModal} onClose={() => setOpenModal(false)}>
              <DeleteNews newsId={newsId} imageUrl={imageUrl} setOpenModal={setOpenModal}
                onUpdateLocation={onUpdateLocation} />
            </AskModal>
          )}
        </table>

      </div>
    </>
  );
};

export default NewNevents;
