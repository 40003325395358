import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

import { useAppContext } from "../../context/AuthProvider";

import "./OutletTopbar.css";

const OutletTopbar = () => {
  const {
    toggleLeftSidebar,
    setToggleLeftSidebar,
    toggleTopbar,
    setToggletopbar,
  } = useAppContext();

  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  const updateTime = () => {
    const current = new Date();
    const formattedTime = formatAMPM(current);
    const formattedDate = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    setCurrentTime(formattedTime);
    setCurrentDate(formattedDate);
  };

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
  };

  useEffect(() => {
    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="OTB-root-container">
      <div className="left-sidebar-toggle-btn">
        <button
          onClick={() => {
            setToggleLeftSidebar(!toggleLeftSidebar);
          }}
        >
          <img src="/icons/toggler.svg" alt="toggler-left" />
        </button>
      </div>
      <div className={`OTB-container ${toggleTopbar ? "active" : ""}`}>
        <div className="close">
          <div
            onClick={() => {
              setToggletopbar(false);
            }}
          >
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="dateTime">
          <div className="currentDate">
            <ReactSVG src="/icons/Calendar.svg" />
            <h2>{currentDate}</h2>
          </div>
          <div className="currentTime">
            <ReactSVG src="/icons/Time.svg" />
            <h2>{currentTime}</h2>
          </div>
        </div>
        <div className="nav-right">
                    <div className="profile">
            <div className="profileImage">
              
              <img src="/images/pp.jpg"  alt=""/>
            </div>
            <div className="profileInfo">
              <h3>SPM</h3>
              <h4>Master Admin</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="right-sidebar-toggle-btn">
        <button
          onClick={() => {
            setToggletopbar(!toggleTopbar);
          }}
        >
          <img src="/icons/toggler.svg" alt="toggler-right" />
        </button>
      </div>
    </div>
  );
};

export default OutletTopbar;
