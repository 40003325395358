// AddNewProject.js
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from 'react-toastify';
import axios from "../../../api/axios";
import styles from "./AddNewProject.module.css";

function AddNewProject() {
  const [inputs, setInputs] = useState({
    name: "",
    description: "",
    location: "",
    buildingHeight: "",
    noOfApartment: "",
    facing: "",
    landSize: "",
    noOfCarParking: "",
    apartmentSize: "",
    unitPerFloor: "",
    apartmentArrangement: "",
    noOfLifts: "",
    address: "",
    brochure: null,
    brochureName: "",
    image: null,
    imageName: "",
    images: [],
    imagesName: [],
    map: { lat: 0, lon: 0 },
    feature: [],
    amenities: [],
  });

  
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [err, setErr] = useState("")
  const [success, setSuccess] = useState("")
  
  const featureOptions = [
    "Spacious Living Areas",
    "Modern Kitchen with Appliances",
    "Luxurious Bathrooms",
    "Scenic Views from Every Window",
    "Smart Home Automation System"
  ];

  const amenityOptions = [
    "Swimming Pool and Spa",
    "High-Speed Internet Access",
    "24/7 Security and Surveillance",
    "Private Balconies or Patios",
    "Fitness Center with State-of-the-Art Equipment",
  ];

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/location/child`);
        setLocation(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchLocations();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setInputs((values) => {
      if (type === "checkbox") {
        return {
          ...values,
          [name]: checked,
        };
      } else if (type === "file") {
        const uniqueFileNames = Array.from(files).map((file) => ({
          name: uuidv4() + "_" + file.name.replace(/\s+/g, "-"),
          file,
        }));

        if (name === "brochure") {
          return {
            ...values,
            brochureName: uniqueFileNames[0].name,
            brochure: event.target.files[0]
          };
        } else if (name === "image") {
          // Handle single image upload
          const imageFile = uniqueFileNames[0];

          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImage(reader.result);
          };
          // Check if file exists before reading
          if (imageFile?.file) {
            reader.readAsDataURL(imageFile.file);
          } else {
            // If no file is selected, clear preview image
            setPreviewImage(null);
          }

          return {
            ...values,
            imageName: uniqueFileNames[0].name,
            image: event.target.files[0]
          };
        } else if (name === "images") {
          // Clear the existing preview images array
          setPreviewImages([]);
          const newImages = [];
          const newImagesName = [];

          uniqueFileNames.forEach((image) => {
            // Check if the image already exists in the previewImages array
            const exists = newImages.some((preview) => preview === image.file);
            if (!exists) {
              newImages.push(image.file);
              newImagesName.push(image.name);
              const reader = new FileReader();
              reader.onload = () => {
                setPreviewImages((prevImages) => [...prevImages, reader.result]);
              };
              reader.readAsDataURL(image.file);
            }
          });

          return {
            ...values,
            images: newImages,
            imagesName: newImagesName,
          };
        }
      } else {
        // Handle other input types
        return {
          ...values,
          [name]:
            name === "feature" || name === "amenities"
              ? value.split(",")
              : value,
        };
      }
    });
  };
console.log("location:::", location)
  const handleChangeMap = (coordinate, event) => {
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      map: {
        ...values.map,
        [coordinate]: value,
      },
    }));
    console.log(inputs);
  };
  console.log(inputs);

  const handleFeatureChange = (value) => {
    // Toggle the color of the clicked option
    setSelectedOption((prevSelectedOption) => prevSelectedOption === value ? null : value);

    // Toggle the presence of the clicked feature in the selectedFeatures array
    if (selectedFeatures.includes(value)) {
      setSelectedFeatures((prevSelectedFeatures) => prevSelectedFeatures.filter(feature => feature !== value));
    } else {
      setSelectedFeatures((prevSelectedFeatures) => [...prevSelectedFeatures, value]);
    }
  };

  useEffect(() => {
    // Update the feature input value
    setInputs({ ...inputs, feature: selectedFeatures.join(', ') });
  }, [selectedFeatures]);


  const handleAmenityChange = (value) => {
    // Toggle the presence of the clicked amenity in the selectedAmenities array
    if (selectedAmenities.includes(value)) {
      setSelectedAmenities((prevSelectedAmenities) => prevSelectedAmenities.filter(amenity => amenity !== value));
    } else {
      setSelectedAmenities((prevSelectedAmenities) => [...prevSelectedAmenities, value]);
    }
  };

  useEffect(() => {
    // Update the amenity input value
    setInputs({ ...inputs, amenities: selectedAmenities.join(', ') });
  }, [selectedAmenities]);


  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();

    try {
      console.log("posting", inputs);
      const response = await axios.post(
        `/projects`,
        inputs
      );

      //uplaod the image
      const formData = new FormData();
      formData.append("imageName", inputs.imageName); // Append image name
      formData.append("brochureName", inputs.brochureName);  // Append pdf name

      if (inputs.image) {
        formData.append("image", inputs.image, inputs.imageName);
      }
      if (inputs.brochure) {
        formData.append("pdf", inputs.brochure, inputs.brochureName);
      }

      if (Array.isArray(inputs.images)) {
        inputs.images.forEach((image, index) => {
          formData.append(`imagesName_${index}`, inputs.imagesName[index]);
          const fileName = inputs.imagesName[index]
          formData.append(`image`, image, fileName);
        });
      }

      const res = await axios.post("/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response:", res.data);

      if (response.status == 200) {
        toast.success("Project Uploaded Successfully !")
      } else if (response.status == 400) {
        toast.error("Project Name Required")
      }

    } catch (error) {
      setErr("failed to upload !")
      toast.error("Failed to upload the project!")
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false)
    }

    console.log(inputs);
  };

  return (
    <div className={styles.container}>
      <h2>Add a new project</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          {/* Project Details */}
          {/* name and Desctiption */} 
          <div className={styles.formSection}>
            <label>
              Project Name:
              <input
                type="text"
                name="name"
                value={inputs.name}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
              Project Description:
              <textarea
                name="description"
                value={inputs.description}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          {/* Type and Locaton  */}
          <div className={styles.formSection}>
            <label>
              Development Type:
              <select
                name="developmentType"
                value={inputs.developmentType}
                onChange={handleChange}
                className={styles.dropdown}
              >
                <option value="">Select</option>
                <option value="Current">Current</option>
                <option value="Upcoming">Upcoming</option>
                <option value="Completed">Completed</option>
              </select>
            </label>
            <label>
              Property Type
              <select
                name="propertyType"
                value={inputs.propertyType}
                onChange={handleChange}
                className={styles.dropdown}
              >
                <option value="">Select</option>
                <option value="Residential">Residential</option>
                <option value="Commercial">Commercial</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Rental">Rental</option>
                <option value="Rental">Land</option>
              </select>
            </label>
            <label>
              Project's Location
              <select
                name="location"
                value={inputs.location}
                onChange={handleChange}
                className={styles.dropdown}
              >
                <option value="">Select</option>
                {location.map((l) => (
                  <>      <option value={l.locationName}>{l.locationName}</option></>
                ))}

              </select>
            </label>
          </div>

          {/* Building and Apartment Details */}
          <div className={styles.formSection}>
            <label>
              Building Height: <span> (ex. 50 Meters)</span>
              <input
                type="text"
                name="buildingHeight"
                value={inputs.buildingHeight}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              No. of Apartments: <span> (ex. 30)</span>
              <input
                type="text"
                name="noOfApartment"
                value={inputs.noOfApartment}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Facing <span> (ex. North)</span>
              <input
                type="text"
                name="facing"
                value={inputs.facing}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              landSize <span> (ex. 10 Katha)</span>
              <input
                type="text"
                name="landSize"
                value={inputs.landSize}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>

          <div className={styles.formSection}>
            <label>
              No. Of Car Parking <span> (ex. 12)</span>
              <input
                type="text"
                name="noOfCarParking"
                value={inputs.noOfCarParking}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Apartment Size <span> (ex. 1200)</span>
              <input
                type="text"
                name="apartmentSize"
                value={inputs.apartmentSize}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              No. of Lift: <span> (ex. 5)</span>
              <input
                type="text"
                name="noOfLifts"
                value={inputs.noOfLifts}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Unit Per Floor <span> (ex. 2)</span>
              <input
                type="text"
                name="unitPerFloor"
                value={inputs.unitPerFloor}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              apartment Arrangement
              <input
                type="text"
                name="apartmentArrangement"
                value={inputs.apartmentArrangement}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
              address
              <input
                type="text"
                name="address"
                value={inputs.address}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <div className={styles.mapSection}>
              <span>Input Map Data</span>
              <label>
                Latitude (lat) <span>(ex. 23.143)</span>
                <input
                  type="text"
                  name="lat"
                  value={inputs.map.lat}
                  onChange={(e) => handleChangeMap("lat", e)}
                  className={styles.inputField}
                />
              </label>
              <label>
                Longitude (lon) <span>(ex. 23.27)</span>
                <input
                  type="text"
                  name="lon"
                  value={inputs.map.lon}
                  onChange={(e) => handleChangeMap("lon", e)}
                  className={styles.inputField}
                />
              </label>
            </div>
          </div>
        </div>
        {/* Feature and amenities */}
        <div className={`${styles.formSection} ${styles.fea}`}>
          <label >
            Features <span> (ex. Write every feature with "," )</span>
            <textarea
              name="feature"
              value={inputs.feature}
              onChange={handleChange}
              className={`${styles.inputField} ${styles.feature}`}
            />
          </label>
          <label className={styles.suggest}>
            Quick add Features
            <div className={styles.optionsContainer}>
              <div className={styles.optionsList}>
                {featureOptions.map((option, index) => (
                  <div key={index} onClick={() => handleFeatureChange(option)}
                    style={{ color: selectedFeatures.includes(option) ? '#A1A1A0' : 'inherit' }}
                  >{option}</div>
                ))}
              </div>
            </div>
          </label>
        </div>

        <div className={`${styles.formSection} ${styles.ame}`}>
          <label>
            Amenities <span> (ex. Write every Amenities with "," )</span>
            <textarea
              name="amenities"
              value={inputs.amenities}
              onChange={handleChange}
              className={`${styles.inputField} ${styles.amenities}`}
            />
          </label>

          <label className={styles.suggest} >
            Quick Add Amenities
            <div className={styles.optionsContainer}>
              <div className={styles.optionsList}>
                {amenityOptions.map((option, index) => (
                  <div key={index} onClick={() => handleAmenityChange(option)}
                    style={{ color: selectedAmenities.includes(option) ? '#A1A1A0' : 'inherit' }}
                  >{option}</div>
                ))}
              </div>
            </div>
          </label>
        </div>

        {/* image Section */}
        <div className={`${styles.formSection}`}>
          <div className={styles.singleImgSctn}>
            <label>
              Main Image (Single Image) <span>(Max File size 1mb)</span>
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                name="image"
                checked={inputs.image}
                onChange={handleChange}
                className={styles.fileInput}

              />
            </label>
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                className={styles.previewImage}
              />
            )}
          </div>
          <div className={styles.multiImgSctn}>
            <label>
              Brochure<span>(supported file type PDF Only, Max size 1mb, )</span>
              <input
                type="file"
                multiple
                name="brochure"
                checked={inputs.brochure}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
          </div>
        </div>
        <div className={`${styles.formSection}`}>
          <div className={styles.galleryImgSection}>
            <label>
              Gallery Images (Multiple Images) <span>(Max File size 1mb)</span>
              <input
                type="file"
                multiple
                name="images"
                checked={inputs.images}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImages.length > 0 && (
              <div className={styles.previewGalleryImages}>
                {previewImages.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className={styles.multiPreviewImage}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <button type="submit" className={styles.submitButton}>
        {loading ? "Loading...." : "Upload Project"} 
        </button>
        <span className={styles.error} >{err}</span>
      </form>
    </div>
  );
}

export default AddNewProject;
