import React, { useEffect, useRef, useState } from "react";
import styles from "./HomeAssentials.module.css";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
const server = process.env.REACT_APP_SERVER_URL;
const MAX_FILE_SIZE = 850 * 1024; 

const HomeAssentials = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [inputs, setInputs] = useState({
    offerProject: "",
    offerLine: "",
    projectId: "",
    YtVideoUrl: "",
    images: [],
    slider: [],
    images2: [],
    gallery: [],
    tag_line: "",
    bench_marks: []
  });

  const [previewImages, setPreviewImages] = useState(null);
  const [oldSlider, setOldSlider] = useState(null);

  const [previewImages2, setPreviewImages2] = useState([]);
  const [oldGalleryImg2, setOldGalleryImg2] = useState([])



  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  const fetchProject = async () => {
    try {
      const response = await axios.get(`/home`);
      console.log("fetchbyId:::", response.data[0]);
      setInputs(response.data[0]);
      setOldSlider(response.data[0].slider || []);
      setOldGalleryImg2(response.data[0].gallery);

    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;
    console.log("show files::", files);
    // Update specific fields within offerProject
    if (name === "projectSlug" || name === "offerLine" || name === "projectId") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        offerProject: {
          ...prevInputs.offerProject,
          [name]: value,
        },
      }));
    }  else if (name === "image") {

            // Check if files exist and are within the size limit
            const validFiles = Array.from(files).filter(
              (file) => file.size <= MAX_FILE_SIZE
            );
      
            if (validFiles.length < files.length) {
              // Some files were too large, show a warning
              toast.warn(
                "Some images exceeded the maximum size of 800 KB and were not selected."
              );
              // Clear input field after warning
              inputRef.current.value = null;
            }


      const uniqueFileNames = Array.from(files).map((file) => ({
        name: uuidv4() + "_" + file.name.replace(/\s+/g, ""),
        file,
      }));
      console.log("condition true and files::::", uniqueFileNames);

      setPreviewImages([]);
      const newImages = [];
      const newImagesName = [];

      uniqueFileNames.forEach((image) => {
        // Check if the image already exists in the previewImages array
        const exists = newImages.some((preview) => preview === image.file);
        if (!exists) {
          newImages.push(image.file);
          newImagesName.push(image.name);
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImages((prevImages) => [...prevImages, reader.result]);
          };
          reader.readAsDataURL(image.file);
        }
      });
      
      console.log("newImages: New ImagesName:", newImages, newImagesName);
      // Handle other input types
      setInputs((prevInputs) => ({
        ...prevInputs,
        images: newImages,
        slider: newImagesName,
      }));

    }else if (name === "images2") {
      // Clear the existing preview images array
      setPreviewImages2([]);

      const uniqueGalleryFileNames = Array.from(files).map((file) => ({
        name: uuidv4() + "_" + file.name.replace(/\s+/g, "-"),
        file,
      }));

      const newImages = [];
      const newImagesName = [];
      console.log("images2::", uniqueGalleryFileNames);
      uniqueGalleryFileNames.forEach((image) => {
        // Check if the image already exists in the previewImages array
        const exists = newImages.some((preview) => preview === image.file);
        if (!exists) {
          newImages.push(image.file);
          newImagesName.push(image.name);
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImages2((prevImages) => [...prevImages, reader.result]);
          };
          reader.readAsDataURL(image.file);
        }
      });


      setInputs((prevInputs) => ({
        ...prevInputs,
        images2: newImages,
        gallery: newImagesName,
      }));

    } else {
      setInputs((prevInputs) => ({ ...prevInputs, [name]:  name === "bench_marks"
              ? value.split(",")
              : value, }));
    }
  }


  console.log("check Inputs::", inputs);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log("check INputs after submit::", inputs);
    try {
      const newInputs = {
        ...inputs,
        slider: inputs.images ? [...inputs.slider, ...oldSlider] : [...inputs.slider],
        gallery: inputs.images2 && Array.isArray(oldGalleryImg2) 
        ? [...inputs.gallery, ...oldGalleryImg2] 
        : [...inputs.gallery || []]
        
      };
      console.log("new Inputs:", inputs);

      const response = await axios.put(`/home`, newInputs);

      console.log("put Response:::", response.status);

      if (inputs.images) {
        const formData = new FormData();
        if (Array.isArray(inputs.images)) {
          console.log("yes its images:::", inputs.images);
          //uplaod the image
          inputs.images?.forEach((image, index) => {
            formData.append(`imagesName_${index}`, inputs.slider[index]);
            const fileName = inputs.slider[index];
            formData.append(`image`, image, fileName);
          });
        }

        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

            // for gallery images 
            if (inputs.images2 && inputs.gallery && inputs.gallery.length > 0) {
              const formData = new FormData();
              if (Array.isArray(inputs.images2)) {
                console.log("yes its images:::", inputs.images2);
                //uplaod the image
                inputs.images2.forEach((image, index) => {
                  formData.append(`imagesName_${index}`, inputs.gallery[index]);
                  const fileName = inputs.gallery[index];
                  formData.append(`image`, image, fileName);
                });
              }
      
              const res = await axios.post("/image", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            }

      if (response.status === 200 || response.status == 201) {
        toast.success("Location Uploaded Successfully !");

      } else if (response.status == 400) {
        toast.error("Location Name Required");
      }
    } catch (error) {
      setErr("failed to upload !");
      toast.error("Failed to update Data!");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);

    }
  };

  const handleImageDelete = async (imageName) => {
    console.log("imageNmae:::", imageName);
    try {
      const updatedSlider = inputs.slider.filter((s) => s !== imageName);

      const updatedGallery = inputs?.gallery?.filter((s) => s !== imageName);

      const updatedInputs = {
        ...inputs,
        slider: updatedSlider,
        gallery: updatedGallery
      };

      await axios.put(`/home`, updatedInputs);

      // Delete and navigate on success
      await axios
        .delete(`/image/${imageName}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          toast.success("Image Deleted Successfully !");
          fetchProject();
        })
        .catch((err) => {
          console.error("Error deleting image:", err);
          toast.error("Failed to delete image.");
        });
    } catch (err) {
      console.error("Error updating or deleting:", err);
      toast.error("Failed to update or delete.");
    }
  };

  console.log("Home assential input<>>>>", inputs);

  return (
    <div className={styles.container}>
      <h2>Home Page assentials</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.formSection}>
            
          <label>
              Homepage Tag Line
              <textarea
                type="text"
                name="tag_line"
                value={inputs?.tag_line}
                onChange={handleChange}
                className={styles.textArea}
              />
            </label>
            </div>
          <div style={{marginTop:"2rem", marginBottom: "2rem", border: "1px solid gray", padding: "0.3rem"}} className={styles.formSection}>

            <label>
              Offer Project Slug (optional) 
              <input
                type="text"
                name="projectSlug"
                value={inputs?.offerProject?.projectSlug}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
              Offer Project Id
              <input
                type="text"
                name="projectId"
                value={inputs?.offerProject?.projectId}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
       
    
            <label>
              Offer Project Slogan
              <input
                type="text"
                name="offerLine"
                value={inputs?.offerProject?.offerLine}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            </div>
          <div className={styles.formSection}>
            <label>
              Youtube video id (from url after "=") <span> (ex: tWbUG3f4MS8)</span>
              <input
                type="text"
                name="YtVideoUrl"
                value={inputs?.YtVideoUrl}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
        </div>
        <div className={styles.formSection}>

          <label>
          Setting Benchmarks <span> (ex. Write every Benchmarks with "," )</span>
            <textarea
              name="bench_marks"
              value={inputs.bench_marks}
              onChange={handleChange}
              className={`${styles.inputField} ${styles.amenities}`}
            />
          </label>
        </div>


                {/* image Section */}
                <div style={{marginTop: "1rem"}} className={styles.formSection}>
         Home Page Main Slider Images <span className={styles.notes} >(File size under 800kb, Image Dimention 1550*780 px )</span>
          <div className={styles.singleImgSctn}>
            <label>
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                multiple
                name="images"
                checked={inputs?.images}
                onChange={handleChange}
                className={styles.fileInput}
                ref={inputRef} 
                accept="image/*"
              />
            </label>
            {previewImages?.length > 0 ? (
              <div className={styles.previewGalleryImages}>
                {previewImages.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className={styles.multiPreviewImage}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.oldPreviewBox}>
                {inputs?.slider?.map((s) => (
                  <div className={styles.imgBox}>
                    <span onClick={() => handleImageDelete(s)}>Delete</span>
                    <img
                      src={`${server}/image/${s}`}
                      alt="Preview"
                      className={styles.multiPreviewImage}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

      {/* Home Slider Image Ends  */}

      {/* Gallery section started  */} 
            <div style={{marginTop: "2rem"}} className={styles.formSectionn}>
          Digital Display Images <span className={styles.notes} >(File size under 500kb, random size supported)</span>
          <div className={styles.galleryImgSection}>
            <label>
              <input
                type="file"
                multiple
                name="images2"
                checked={inputs.images2}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImages2?.length > 0 ? (
              <div className={styles.previewGalleryImages2}>
                {previewImages2.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className={styles.multiPreviewImage}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.oldPreviewBox}>
                { inputs && Array.isArray(inputs.gallery) &&  inputs?.gallery?.map((s) => (
                  <div className={styles.imgBox}>
                    <span onClick={() => handleImageDelete (s, "flore")}>Delete</span>
                    <img
                      src={`${server}/image/${s}`}
                      alt="Preview"
                      className={styles.multiPreviewImage}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Gallery section end  */}

        <button
          style={{ marginTop: "2vw", backgroundColor: loading && "#584db8" }}
          type="submit"
          className={styles.submitButton}
        >
          {loading ? "Uploading...., Please Wait" : "Update Info"}
        </button>
      </form>
    </div>
  );
};

export default HomeAssentials;
