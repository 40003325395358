import axios from "axios";
import React, { useState } from "react";

import "./AddAdminModal.css";

// Modal.setAppElement('#root'); // Set the root element for accessibility
const AddAdmin = ({ isOpen, closeModal, outletData }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    outlets: [],
    role: "",
  });

  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    // Destructuring
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      outlets: prevState.outlets.includes(value)
        ? prevState.outlets.filter((data) => data !== value)
        : [...prevState.outlets, value],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requestData = {
      name: formData.name,
      phone: formData.mobile,
      email: formData.email,
      password: formData.password,
      outlets: formData.outlets,
      role: formData.role,
    };

    console.log("crate amdin requestData", requestData)
    axios
      .post("/registerAdmin", requestData)
      .then((response) => {
        console.log("POST request success:", response.data);

        // Clear the form fields
        setFormData({
          name: "",
          mobile: "",
          email: "",
          password: "",
          outlets: [],
          role: "",
        });
      })
      .catch((error) => {
        console.error("POST request error:", error);
      });
      closeModal()
  };

  return (
    <section className="modal-wrapper">
      <div className="modal-header">
        <div className="header-left">
          <h2>Add Admin</h2>
        </div>
        <div className="header-right">
          <button className="btn-accept" onClick={handleSubmit}>
            Add 
          </button>
        </div>
      </div>
      <div className="modal-body">
        <div className="body-left">
          <form>
            <div>
              <div className="form-group">
                <label htmlFor="name">Admin Name</label> <br />
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="mobile">Mobile No.</label>
                <br />
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <br />
                <input
                  type="password"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  name="password"
                  required
                />
              </div>
            </div>
          </form>
        </div>
        <div className="middle-divider"></div>
        <div className="body-right">
          <div className="outlet-part">
            <h4>Select Outlet</h4>
            <div className="outlet">
              {outletData.map((outlet) => {
                return (
                  <div key={outlet._id}>
                    <input
                      type="checkbox"
                      name="outlets"
                      value={outlet?._id}
                      onChange={handleCheckboxChange}
                    />{" "}
                    <label>{outlet?.name}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="role-part">
            <h4>Admin Role</h4>
            <select onChange={handleChange} name="role">
              <option>Select</option>
              <option>SuperAdmin</option>
              <option>Admin</option>
              <option>Manager</option>
            </select>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddAdmin;
