import React from "react";
import { Navigate } from "react-router-dom";

import { useAppContext } from "../context/AuthProvider";

const AdminRoute = ({ children }) => {
    const { auth } = useAppContext();

  if (
    auth.role === "Admin" || auth.role === "SuperAdmin"
  ) {
    return children;
  }
  return <Navigate to="/login" replace />;
};

export default AdminRoute;