import axios from "axios";
// console.log("url in Axios::", process.env.REACT_APP_SERVER_URL);
const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  // baseURL: `${process.env.REACT_APP_SERVER_URL || "https://server.secure-bd.com/api"}`,
});

console.log("env url:::", process.env.REACT_APP_SERVER_URL)
api.interceptors.request.use(async function (config) {
  const token = localStorage.getItem("token");
  config.headers["x-access-token"] = token;
  return config;
});

export default api;
 