// AddNewProject.js
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import axios from "../../../api/axios";
import styles from "./EditReview.module.css";
import { useParams } from "react-router-dom";
const server = process.env.REACT_APP_SERVER_URL

function EditReview() {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)

  const [singleClientData, setSingleClientData] = useState({
    name: "",
    designation: "",
    comments: "",
    imageUrl: "",
    image: null
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [oldImgName, setOldImgName] = useState("")

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await axios.get(`/landownerReview/${id}`);
        console.log("fetchbyId:::", response.data)
        setSingleClientData(response.data);
        setOldImgName(response.data.imageUrl)
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };
    fetchClient();
  }, [id]);
;

console.log("singleClientData:::", singleClientData)
  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setSingleClientData((values) => {

      if (name === "image") {
        const uniqueFileNames = Array.from(files).map(file => ({
          name: uuidv4() + '_' + file.name.replace(/\s+/g, ''),
          file
        }));

        const reader = new FileReader();
        reader.onload = () => {
          setPreviewImage(reader.result);
        };
        const imageFile = uniqueFileNames[0];
        // Check if file exists before reading
        if (imageFile?.file) {
          reader.readAsDataURL(imageFile.file);
        } else {
          setPreviewImage(null);
        }
        return {
          ...values,
          imageUrl: uniqueFileNames[0].name,
          image: event.target.files[0]
        };
      }
      else {
        // Handle other input types
        return {
          ...values,
          [name]: value,
        };
      }
    });
  };


  console.log(singleClientData);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // console.log("posting", singleClientData)
      // Send formData to your server using axios or your preferred HTTP client
      const response = await axios.put(`/landownerReview/${id}`, singleClientData);

      if (singleClientData.image) {
        console.log("singlimg::", singleClientData.image); 
        //uplaod the image
        const formData = new FormData();
        formData.append("image", singleClientData.image, singleClientData.imageUrl);
        const res = await axios.post("/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", res.data);
      }

      // if image change then delte old image 
      if (response.status === 200 && oldImgName !== singleClientData.imageUrl) {
        const res = await axios.delete(`/image/${oldImgName}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        console.log("res", res)
      }

      if (response.status === 200 || response.status === 201) {
        toast.success("Client Updated Successfully !")
      } else if (response.status === 400) {
        toast.error("Client is not Updated!")
      }

      console.log("Response:", response.data);
    } catch (error) {
      console.log("error:::", error);
      toast.error("Unexprected Error Found !")
      console.error("Error submitting form:", error);
    }
    console.log(singleClientData);
  };

  return (
    <div className={styles.container}>
      <h2>Update Landonwer Data</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>
          {/* Project Details */}
          {/* name and Desctiption */}
          <div className={styles.formSection}>
            <label>
            Landonwer Name:
              <input
                type="text"
                name="name"
                value={singleClientData?.name}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
            <label>
            Landonwer Designation:
              <input
                type="text"
                name="designation"
                value={singleClientData?.designation}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
            <label>
            Landonwer Comments:
              <textarea
                type="text"
                name="comments"
                value={singleClientData.comments}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
           
          </div>
          {/* image Section */}
          <div className={styles.formSection}>
            <div className={styles.singleImgSctn}>
              <label>
              Landonwer Image (Single Image)
                <input
                  // style={{backgroundColor: "gray"}}
                  type="file"
                  name="image"
                  checked={singleClientData.image}
                  onChange={handleChange}
                  className={styles.fileInput}
                />
              </label>
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="Preview"
                  className={styles.previewImage}
                />
              ) : <img
                src={`${server}/image/${singleClientData.imageUrl}`}
                alt="Preview"
                className={styles.previewImage}
              />}
            </div>
          </div>
        </div>
        <button type="submit" className={styles.submitButton}>
          Upload Landonwer
        </button>
      </form>
    </div>
  );
}

export default EditReview;
