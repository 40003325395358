import { useEffect, useState } from "react";
import axios from "./../../../../../src/api/axios"
import styles from "./View.module.css"
const View = ({ view, setOpenModalView, onUpdateLocation  }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [data, setData] = useState()
console.log("review Id for View::", view);
    useEffect(() => {
        // handleYesClick(reviewId)
    },[view])

    // const handleYesClick = async () => {
    //     setLoading(true);
    //     try {
    //         const res = await axios.get(`/formData/${reviewId}`);
    //         console.log("form single get:", res);
    //         setData(res.data)
    //         // Close the modal after successful deletion
    //         onUpdateLocation() 
    //     } catch (error) {
    //         setError(error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleCloseClick = () => {
        setOpenModalView(false); 
    };

    return (
        <>
            <div className={`${styles.views}`}>
                <p>Name: {view?.name}</p>
                <p>Phone: {view?.phone}</p>
                <p>Email: {view?.email}</p>
                <p>Query For: {view?.queryFor}</p>
                <p>Message: {view?.message}</p>
                <button className={`${styles.btn} + ask-close-btn`}  onClick={handleCloseClick}>Close</button>
            </div>
        </>
    )
}

export default View;
