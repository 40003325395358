import React, { useEffect, useState } from "react";
import AdminList from "./AdminList";
import "./Admin.css";
import axios from "../../api/axios";
import Modal from "../Modals/Modal";
import AddAdmin from "./AddAdminModal";

const Admin = () => {
  const [outletData, setOutletData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getOutletdata = async () => {
      try {
        const response = await axios.get("/outlet");
        setOutletData(response.data);
        // console.log("statusText=>", response.statusText);
      } catch (error) {
        console.log("Axios Error: ", error);
      }
    };
    getOutletdata();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddAdmin = () => {
    setIsModalOpen(true);
  };

  return (
    <div>
      <div className="admin">
        <div className="admin-header">
          <span className="page-title">Admin</span>
          <div className="filterItem">
            {/* <img src="/icons/Filter.svg" alt="" /> */}img
            <p>Outlet</p>
            <select id="item-type">
              <option value="">All</option>
              {outletData?.map((data) => (
                <option value={data._id}>{data.name}</option>
              ))}
            </select>
          </div>
          <button
            onClick={() => handleAddAdmin()}
            className="add-button"
          >
            Add Admin +
          </button>
        </div>
        <AdminList />
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
       
        <AddAdmin
          isOpen={isModalOpen}
          closeModal={closeModal}
          outletData={outletData}
        />
      </Modal>
    </div>
  );
};

export default Admin;
