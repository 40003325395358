import { React, useContext, useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Admin from "./components/Admin/Admin";
import Login from "./components/Forms/Login";
import Layout from "./components/Layout/Layout";
import Locations from "./components/Locations/Locations";
import AllProject from "./components/pages/Project/AllProject";
import AddNewProject from "./components/ProjectCURD/addProject/AddNewProject";
import AddNewLocation from "./components/LocationCURD/addLocation/AddNewLocation";
import AddNewsNevent from "./components/NewsNeventCURD/addNewsNevent/AddNewsNevent";
import HomeAssentials from "./components/pages/homeAssentials/HomeAssentials";
import EditProject from "./components/ProjectCURD/editProject/EditProject";
import PrivateRoute from "./Routes/PrivateRoutes";
import SuperAdminRoute from "./Routes/SuperAdminRoute";

import "./App.css";
import { ToastContainer } from "react-toastify";
import AboutPage from "./components/pages/aboutPage/AboutPage";
import EditLocation from "./components/LocationCURD/editLocation/EditLocation";
import EditNews from "./components/NewsNeventCURD/editNewsNevent/EditNewsNevent";
import ContactPage from "./components/pages/contactInfo/ContactInfo";
import FooterInfo from "./components/pages/footerInfo/FooterInfo";
import NewNevents from "./components/pages/newNevents/NewNevents";
import Clinets from "./components/pages/clients/Client";
import Landowner from "./components/pages/landOwnerReviews/LandownerReviews";
import AddNewClient from "./components/clientCURD/addClient/AddNewClient";
import ClientReviews from "./components/pages/clientReviews/ClientReviews";
import Managements from "./components/pages/managements/Managements";
import OthersInfo from "./components/pages/othersInfo/OthersInfo";
import SecureTeam from "./components/pages/secureTeam/SecureTeam";
import Sustainability from "./components/pages/sustainability/Sustainability";
import EditClient from "./components/clientCURD/editClient/EditClient";
import AddNewReview from "./components/clientReviewCURD/addReview/AddNewReview";
import AddNewLWReview from "./components/landOwnerReviewCURD/addReview/AddNewReview";
import EditReview from "./components/clientReviewCURD/editReview/EditReview";
import EditLWReview from "./components/landOwnerReviewCURD/editReview/EditReview";
import AddNewGreen from "./components/greenSectionCURD/AddGreen/AddGreen";
import EditGreen from "./components/greenSectionCURD/editGreen/EditGreen";
import AddNewMaterials from "./components/materialsSectionCURD/addMaterials/AddMaterials";
import EditMaterials from "./components/materialsSectionCURD/editMaterials/EditMaterials";
import EditDirector from "./components/directorCRUD/editDirector/EditDirector";
import AddDirectors from "./components/directorCRUD/addDirector/AddNewDirector";
import CsrGallery from "./components/pages/csrGallery/CsrGallery";
import AddNewCSRImg from "./components/CsrGalleryCURD/addCSRGallery/addNewCSRGallery";
import EditCsrImg from "./components/CsrGalleryCURD/editCsrGallery/EditCsrGallery";
import OurStory from "./components/pages/ourStory/OurStory";
import EditBrandStory from "./components/BrandStoryCURD/editCsrGallery/EditBrandStory";
import CsrPage from "./components/pages/csrPage/CsrPage";
import AdminRoute from "./Routes/AdminRoute";
import FormData from "./components/pages/formData/FormData";

// import "./pages/Outlets.css";

const App = () => {
  // const { token, setToken } = useContext(AuthContext);

  const token = localStorage.getItem("token");
  return (
    <Router>
      <Layout>
        <ToastContainer
          newestOnTop
          autoClose={4000}
          pauseOnHover
          pauseOnFocusLoss
        />
        <Routes>
          <Route
            path="/admin"
            exact
            element={
              <SuperAdminRoute>
                <Admin />
              </SuperAdminRoute>
            }
          />
          <Route
            path="/"
            element={
              <AdminRoute>
                <FormData />
              </AdminRoute>
            }
          />
          <Route
            path="/projects"
            element={
              <AdminRoute>
                <AllProject />
              </AdminRoute>

            }
          />
          <Route
            path="/homepageassential"
            element={
              <AdminRoute>
                <HomeAssentials />
              </AdminRoute>

            }
          />
          <Route
            path="/csr"
            element={
              <AdminRoute>
                <CsrPage />
              </AdminRoute>

            }
          />
          <Route
            path="/secureTeam"
            element={
              <AdminRoute>
                <SecureTeam />
              </AdminRoute>

            }
          />
          <Route
            path="/sustainability"
            element={
              <AdminRoute>

                <Sustainability />
              </AdminRoute>
            }
          />
          <Route
            path="/about"
            element={
              <AdminRoute>

                <AboutPage />
              </AdminRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <AdminRoute>

                <ContactPage />
              </AdminRoute>
            }
          />
          <Route
            path="/footer"
            element={
              <AdminRoute>

                <FooterInfo />
              </AdminRoute>
            }
          />
          <Route
            path="/locations"
            element={
              <AdminRoute>

                <Locations />
              </AdminRoute>
            }
          />
          <Route
            path="/csrgallery"
            element={
              <AdminRoute>

                <CsrGallery />
              </AdminRoute>
            }
          />
          <Route
            path="/story"
            element={
              <AdminRoute>

                <OurStory />
              </AdminRoute>
            }
          />
          <Route
            path="/newsNevents"
            element={
              <AdminRoute>
                <NewNevents />
              </AdminRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <AdminRoute>
                <Clinets />
              </AdminRoute>
            }
          />
          <Route
            path="/form"
            element={
              <AdminRoute>
                <FormData />
              </AdminRoute>
            }
          />
          <Route
            path="/landowner"
            element={
              <AdminRoute>
                <Landowner />
              </AdminRoute>
            }
          />
          <Route
            path="/clientReviews"
            element={
              <AdminRoute>

                <ClientReviews />
              </AdminRoute>
            }
          />
          <Route
            path="/managements"
            element={
              <AdminRoute>

                <Managements />
              </AdminRoute>
            }
          />
          <Route
            path="/othersInfo"
            element={
              <AdminRoute>

                <OthersInfo />
              </AdminRoute>
            }
          />
          <Route
            path="/AddNewProject"
            element={
              <AdminRoute>

                <AddNewProject />
              </AdminRoute>
            }
          />
          <Route
            path="/AddNewLocation"
            element={
              <AdminRoute>

                <AddNewLocation />
              </AdminRoute>
            }
          />
          <Route
            path="/addNewCsrImg"
            element={
              <AdminRoute>

                <AddNewCSRImg />
              </AdminRoute>
            }
          />
          <Route
            path="/AddNews"
            element={
              <AdminRoute>
                <AddNewsNevent />
              </AdminRoute>
            }
          />
          <Route
            path="/addClient"
            element={
              <AdminRoute>
                <AddNewClient />
              </AdminRoute>
            }
          />
          <Route
            path="/editClient/:id"
            element={
              <AdminRoute>
                <EditClient />
              </AdminRoute>
            }
          />
          <Route
            path="/addReview"
            element={
              <AdminRoute>
                <AddNewReview />
              </AdminRoute>
            }
          />
          <Route
            path="/addLandownerReview"
            element={
              <AdminRoute>
                <AddNewLWReview />
              </AdminRoute>
            }
          />
          <Route
            path="/editReview/:id"
            element={
              <AdminRoute>
                <EditReview />
              </AdminRoute>
            }
          />
          <Route
            path="/editLWReview/:id"
            element={
              <AdminRoute>
                <EditLWReview />
              </AdminRoute>
            }
          />

          <Route
            path="/addGreen"
            element={
              <AdminRoute>
                <AddNewGreen />
              </AdminRoute>
            }
          />

          <Route
            path="/editGreen/:id"
            element={
              <AdminRoute>
                <EditGreen />
              </AdminRoute>
            }
          />
          <Route
            path="/addMaterials"
            element={
              <AdminRoute>
                <AddNewMaterials />
              </AdminRoute>
            }
          />


          <Route
            path="/editMaterials/:id"
            element={
              <AdminRoute>
                <EditMaterials />
              </AdminRoute>
            }
          />
          <Route
            path="/addDirector"
            element={
              <AdminRoute>
                <AddDirectors />
              </AdminRoute>
            }
          />


          <Route
            path="/editDirector/:id"
            element={
              <AdminRoute>
                <EditDirector />
              </AdminRoute>
            }
          />
          <Route
            path="/editProject/:id"
            element={
              <AdminRoute>
                <EditProject />
              </AdminRoute>
            }
          />
          <Route
            path="/editLocation/:id"
            element={
              <AdminRoute>
                <EditLocation />
              </AdminRoute>
            }
          />
          <Route
            path="/editCsrImg/:id"
            element={
              <AdminRoute>
                <EditCsrImg />
              </AdminRoute>
            }
          />
          <Route
            path="/editStory/:id"
            element={
              <AdminRoute>
                <EditBrandStory />
              </AdminRoute>
            }
          />
          <Route
            path="/editNews/:id"
            element={
              <AdminRoute>
                <EditNews />
              </AdminRoute>
            }
          />
          <Route path="/login" exact element={<Login />} />
          <Route
            path="/*"
            exact
            element={
              token ? (
                <PrivateRoute>
                  <AllProject />
                </PrivateRoute>
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
