// AddNewProject.js
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import axios from "../../../api/axios";
import styles from "./addNewsNevent.module.css";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
function AddNewLocation() {
const [inputs, setInputs] = useState({
  title: "", 
  des: "",
  imageUrl: "",
  image: null,
  images: [],
  imageUrls: []
})

const navigate = useNavigate(); 
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState("")
  const [success, setSuccess] = useState("")
  const [previewImages, setPreviewImages] = useState([]);

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    // Handle different types of input elements
    setInputs((values) => {
       if (type === "file") {
        const uniqueFileNames = Array.from(files).map((file) => ({
          name: uuidv4() + "_" + file.name.replace(/\s+/g, ""),
          file,
        }));

        if (name === "image") {
          // Handle single image upload
          const imageFile = uniqueFileNames[0];
          const reader = new FileReader();
          reader.onload = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(imageFile.file);
          return {
            ...values,
            imageUrl: uniqueFileNames[0].name,
            image: event.target.files[0]
          };
        }  else if (name === "images") {
          // Clear the existing preview images array
          setPreviewImages([]);
          const newImages = [];
          const newImagesName = [];
    
          uniqueFileNames.forEach((image) => {
            // Check if the image already exists in the previewImages array
            const exists = newImages.some((preview) => preview === image.file);
            if (!exists) {
              newImages.push(image.file);
              newImagesName.push(image.name);
              const reader = new FileReader();
              reader.onload = () => {
                setPreviewImages((prevImages) => [...prevImages, reader.result]);
              };
              reader.readAsDataURL(image.file);
            }
          });
    
          return {
            ...values,
            images: newImages,
            imageUrls: newImagesName,
          };
        }
 
      } else {
        // Handle other input types
        return {...values, 
        [name]: value};
      }
    });
  };

console.log('inputs>>>', inputs);
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    try {
      console.log("posting", inputs);
      const response = await axios.post(
        `/news`,
        inputs
      );

      const formData = new FormData();

      if (inputs.image) {
       //uplaod the image
        formData.append("image", inputs.image, inputs.imageUrl);

    } 

    if (Array.isArray(inputs.images)) {

      inputs.images.forEach((image, index) => {
        formData.append(`imagesName_${index}`, inputs.imageUrls[index]);
        const fileName = inputs.imageUrls[index]
        formData.append(`image`, image, fileName);
      });
    }

    const res = await axios.post("/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Response:", res.data);

      if (response.status === 200 || response.status === 201) {
        toast.success("Location Uploaded Successfully !")
      } else if (response.status === 400) {
        toast.error("Location Name Required")
      }
    
    } catch (error) {
      setErr("failed to upload !")
      toast.error("Failed to upload the project!")
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false)

      setTimeout(() => {
        navigate("/newsNevents"); 
      }, 1500); // 1.5 seconds delay
    }

    console.log(inputs);
  };
  console.log("inpt data", inputs);

  return (
    <div className={styles.container}>
      <h2>Add a New News/Event</h2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.fieldContainer}>

          <div className={styles.formSection}>
            <label>
              News/Event Title:
              <input
                type="text"
                name="title"
                value={inputs.title}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
          <div className={styles.formSection}>
          <label>
              News/Event Description:
              <textarea
                type="text"
                name="des"
                value={inputs.des}
                onChange={handleChange}
                className={styles.inputField}
              />
            </label>
          </div>
        </div>

        {/* image Section */}
        <div className={styles.formSection}>
          <div className={styles.singleImgSctn}>
            <label>
              Add Image
              <input
                // style={{backgroundColor: "gray"}}
                type="file"
                name="image"
                checked={inputs.image}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                className={styles.previewImage}
              />
            )}
          </div>
        </div>

        <div className={`${styles.formSection}`}>
          <div className={styles.galleryImgSection}>
            <label>
              Gallery Images (Multiple Images) <span>(Max File size 1mb)</span>
              <input
                type="file"
                multiple
                name="images"
                checked={inputs.images}
                onChange={handleChange}
                className={styles.fileInput}
              />
            </label>
            {previewImages.length > 0 && (
              <div className={styles.previewGalleryImages}>
                {previewImages.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className={styles.multiPreviewImage}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <button style={{marginTop: "2vw", backgroundColor: loading && "#584db8" }} type="submit" className={styles.submitButton}>
         {loading ? "Loading...." : "Add Location"} 
        </button>
      </form>
    </div>
  );
}

export default AddNewLocation;
