import { useState } from "react";
import axios from "./../../../../src/api/axios"

const DeleteDirector = ({ directorId, setOpenModal, onUpdateLocation  }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    console.log("directorId::", directorId)
    const handleYesClick = async () => {
        setLoading(true);
        try {
            const res = await axios.delete(`/director/${directorId}`);
            console.log("delete response:", res);
            // Close the modal after successful deletion
            setOpenModal(false);
            onUpdateLocation() 
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseClick = () => {
        setOpenModal(false); 
    };

    return (
        <>
          <h4> Are you want to delete this Client?</h4> 
            <div className="ask-alert-bottons">
            {loading ? <>Loading...</> : <>
                <button className="ask-close-btn" onClick={handleYesClick}>Yes</button>
                <button className="ask-close-btn" onClick={handleCloseClick}>Cancel</button>
                </>}
            </div>
        </>
    )
}

export default DeleteDirector;
